import React, { useEffect, useState, useCallback } from "react";
import textilService from "../services/textil.service";
import FiremniCenyBarva from "./FiremniCenyBarva";

const FiremniCenyTab = (props) => {
    const [barvy, setBarvy] = useState([]);
    const [velikosti, setVelikosti] = useState([]);
    const [detail, setDetail] = useState([]);
    const [textilCena, setTextilCena] = useState([]);

    const isActiveTab = props.isActiveTab

    const fetchFiremniData = useCallback(async () => {
        console.log("fetchFiremniData");
        const textilData = await textilService.getColorsAndSizes(props.id);
        setBarvy(textilData.barvy);
        setVelikosti(textilData.velikosti);
        setDetail(textilData.detail);
        setTextilCena(textilData.textil?.cena_textilu);
    }, [props.id]);

    const checkActiveTab = () => {
        return isActiveTab ? " active" : ""
    }


    useEffect(() => {
        fetchFiremniData();
    }, [fetchFiremniData]);

    return (
        <div id="tab-2" className={`tab-pane ${checkActiveTab()}`} >
            <div className="panel-body">
                <fieldset>
                    <div className="row">
                        {/* <div className="col-lg-10"> */}
                        <table className="table table-bordered" >
                            <thead>
                                <tr>
                                    <th>
                                        <center>Barvy</center>
                                    </th>
                                    <th colSpan={velikosti.length}>
                                        <center>Velikosti</center>
                                    </th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    {velikosti.map((onelikost) => (
                                        <td key={`sizeColums${onelikost.velikost.id}`}><center><strong>{onelikost.velikost.nazev}</strong></center></td>
                                    ))}
                                </tr>
                                {barvy.map((barva) => (
                                    <FiremniCenyBarva
                                        barva={barva.barva}
                                        velikosti={velikosti}
                                        detail={detail}
                                        cena={textilCena}
                                        key={barva.barva_id + props.id}
                                        textilId={props.id}
                                    />
                                ))}
                            </tbody>
                        </table>
                        {/* </div> */}
                    </div>
                </fieldset>
            </div>
        </div >
    );
};

export default FiremniCenyTab;
