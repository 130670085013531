import React, { Fragment, useEffect, useState, useCallback } from 'react';
import ItemModal from './ItemModal';
import ItemPagination from './ItemPagination';
import ItemTableRow from './ItemTableRow';

const ItemsList = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [itemSaved, setItemSaved] = useState(false)
    const [itemsCnt, setItemsCnt] = useState()
    const [pageNo, setPageNo] = useState(1);
    const [data, setData] = useState([]);
    const [rowData, setRowData] = useState()
    const [sortDir, setSortDir] = useState(true)
    const [sortBy, setSortBy] = useState('kod')
    const metaData = props.metaData
    const listMetaData = props.listMetaData


    const itemService = props.itemService
    const perPageCnt = 10

    const fetchData = useCallback(async (sortByAttr, sortDirAttr) => {
        let sort = 'ASC'
        switch (sortDirAttr) {
            case true:
                sort = 'ASC'
                break;
            default:
                sort = 'DESC'
                break;
        }
        const itemsData = await itemService.getAllData(pageNo, perPageCnt, sortByAttr, sort);
        setData(itemsData.data);
        setItemsCnt(itemsData.cnt)
    }, [itemService, pageNo]);

    const newItem = () => {
        setRowData({})
        setIsOpen(true);
    }

    const sortDataBy = (name) => {
        console.log('sortDataBy')
        console.log(name)
        console.log(sortBy)
        setSortDir(!sortDir)
        setSortBy(name)
        fetchData(name, !sortDir)
    }

    const getSortClass = (attrName) => {
        console.log('getSortClass')
        console.log(attrName)
        console.log(sortBy)
        console.log(sortDir)
        let retValue = 'fa fa-unsorted';
        if (attrName === sortBy) {
            switch (sortDir) {
                case false:
                    retValue = "fa fa-sort-desc"
                    break;
                case true:
                    retValue = "fa fa-sort-asc"
                    break;
                default:
                    retValue = 'fa fa-unsorted'
                    break;
            }
        }
        return retValue
    }

    useEffect(() => {
        fetchData(sortBy, sortDir);
        setIsOpen(false);
    }, [pageNo, itemSaved, sortBy, sortDir, fetchData]);

    return (
        <Fragment>
            {isOpen && <ItemModal data={rowData} metaData={metaData} setIsOpen={setIsOpen} itemService={itemService} setItemSaved={setItemSaved} itemSaved={itemSaved} />}
            <div className="row">
                <div className="col-lg-12">
                    <div className="ibox">
                        <div className="ibox-title">
                            <h5>{listMetaData.headerLabel}</h5>
                        </div>
                        <div className="ibox-content">
                            <div className="row">
                                <div className="col-sm-5 m-b-xs"></div>
                                <div className="col-sm-4 m-b-xs">
                                    <div className="text-right">
                                        <button onClick={newItem} className="btn btn-primary">
                                            {listMetaData.newLabel}
                                        </button>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="input-group">
                                        <input
                                            placeholder="Hledej"
                                            type="text"
                                            className="form-control form-control-sm"
                                        />
                                        <span className="input-group-append">
                                            <button type="button" className="btn btn-sm btn-primary">
                                                Hledej!
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <table className="table">
                                <thead>
                                    <tr>
                                        {metaData?.map((oneMetaData) => (
                                            <th key={`itemHeader#${oneMetaData.name}`}>
                                                {oneMetaData?.label} &nbsp;
                                                <a onClick={() => sortDataBy(oneMetaData.name)} href={() => false}><i className={getSortClass(oneMetaData.name)} ></i></a>
                                                {/* <i class="fa fa-sort-asc"></i><i class="fa fa-sort-asc"></i><i class="fa fa-sort-desc"></i> */}
                                            </th>
                                        ))}
                                        <th>Akce</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((item) => (
                                        <ItemTableRow key={item.id} metaData={metaData} itemData={item} setIsOpen={setIsOpen} setRowData={setRowData} itemService={itemService} setItemSaved={setItemSaved} itemSaved={itemSaved} pageNo={pageNo} setPageNo={setPageNo} />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <ItemPagination setPageNo={setPageNo} pageNo={pageNo} itemsCnt={itemsCnt} perPageNo={perPageCnt} />
        </Fragment>
    );
};

export default ItemsList;
