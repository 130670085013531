
import { BASE_API_URL } from '../constants';

async function getAllData(pageNo, perPageNo, sortBy, sortDir) {
    return await getAllDodavatel(pageNo, perPageNo, sortBy, sortDir);
  }
  
 
  
  async function getAllDodavatel(pageNo, perPageNo, sortBy, sortDir) {
    const url = `${BASE_API_URL}/dodavatel/${pageNo}/${perPageNo}/${sortBy}/${sortDir}`;
    const data = fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return myJson;
      });
    console.log(data);
    return data;
  }



async function getAll() {
    const url = `${BASE_API_URL}/dodavatel`;
    const data = await fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
    const response = await data.json();
    return response
}
 
export default {
    getAllData,
    getAll
}