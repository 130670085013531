import { BASE_API_URL } from '../constants';

async function getAllTextil(filters = {}) {
    const url = `${BASE_API_URL}/textil/allWithDetails`;
    const data = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(filters),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            'x-access-token': sessionStorage.getItem("accessToken")
        },
    });
    const response = await data.json();
    return response;
}

async function getOneTextil(id) {
    const url = `${BASE_API_URL}/textil/oneWithDetails/${id}`;
    const data = await fetch(url, {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            'x-access-token': sessionStorage.getItem("accessToken")
        },
    });
    const response = await data.json();
    return response;
}

async function getColorsAndSizes(id) {
    const url = `${BASE_API_URL}/textil/colorsAndSizes/${id}`;
    const data = await fetch(url, {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            'x-access-token': sessionStorage.getItem("accessToken")
        },
    });
    const response = await data.json();
    return response;
}

async function toggleExcludedSchool(textilId, velikostId, barvaId) {
    const url = `${BASE_API_URL}/textil/toggleExcludedSchool/${textilId}/${velikostId}/${barvaId}`
    const data = await fetch(url, {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            'x-access-token': sessionStorage.getItem("accessToken")
        },
    });
    const response = await data.json();
    return response;

}

async function toggleExcludedCompany(textilId, velikostId, barvaId) {
    const url = `${BASE_API_URL}/excluded-textil/toggleExcludedTextil/${textilId}/${velikostId}/${barvaId}`
    const data = await fetch(url, {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            'x-access-token': sessionStorage.getItem("accessToken")
        },
    });
    const response = await data.json();
    return response;

}

async function getTextilTabs(textilId, velikostId, barvaId) {
    const url = `${BASE_API_URL}/textil/getTabs/${textilId}`
    const data = await fetch(url, {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            'x-access-token': sessionStorage.getItem("accessToken")
        },
    });
    const response = await data.json();
    return response;

}

async function savePrice(textilId, sizeId, colorId, price) {

    const submittedData = {
        "textilId": textilId,
        "sizeId": sizeId,
        "colorId": colorId,
        "price": price

    }
    const url = `${BASE_API_URL}/textil/savePrice`

    const resData = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(submittedData),
        headers: {
            'Content-Type': 'application/json',
            'Accept': "application/json",
            'x-access-token': sessionStorage.getItem("accessToken")
        }
    });

    const response = resData;
    return response;

}

async function save(submittedFormData) {
    await fetch(`${BASE_API_URL}/textil/save`, {
        method: 'POST',
        body: JSON.stringify(submittedFormData),
        headers: {
            'Content-Type': 'application/json',
            'Accept': "application/json",
            'x-access-token': sessionStorage.getItem("accessToken")
        }
    });
}

export default {
    getAllTextil,
    getOneTextil,
    getColorsAndSizes,
    toggleExcludedSchool,
    toggleExcludedCompany,
    getTextilTabs,
    savePrice,
    save
};
