import { Fragment, useState, useEffect } from "react";
import textilService from "../services/textil.service";

const SkolniCenyBarvaButton = (props) => {

    const [isExcluded, setIsExcluded] = useState(false);

    const textilId = props.textilId
    const velikostId = props.velikostId
    const barvaId = props.barvaId


    const toggleExcludedTextil = async (textilId, velikostId, barvaId) => {
        const response = await textilService.toggleExcludedSchool(textilId, velikostId, barvaId)
        const status = response.status
        console.log("🚀 ~ file: SkolniCenyBarvaButton.js:28 ~ toggleExcludedTextil ~ status:", status)
        const isExcluded = response.isExcluded
        console.log("🚀 ~ file: SkolniCenyBarvaButton.js:30 ~ toggleExcludedTextil ~ isExcluded:", isExcluded)
        if (status === "OK") setIsExcluded(isExcluded)

    }


    useEffect(() => {
        setIsExcluded(props.isExcluded)
    }, [props]);
    return (
        <Fragment>
            <button
                className={`btn ${isExcluded ? "btn-danger" : "btn-info"} btn-xs`}
                type="button"
                id={`zobrazitSkoly#button#${textilId}#${velikostId}#${barvaId}`}
                onClick={() => toggleExcludedTextil(textilId, velikostId, barvaId)}
            >
                <i
                    id={`zobrazitSkoly#${textilId}#${velikostId}#${barvaId}`}
                    className="fa fa-check"
                ></i>
            </button>
        </Fragment>
    )
}


export default SkolniCenyBarvaButton
