import React, { useEffect, useState, useCallback } from 'react';
import TextilListItem from './TextilListItem';
import textilService from '../services/textil.service'
import Select from 'react-select'
import dodavatelService from "../services/dodavatel.service";
import colorService from '../services/barva.service'
import sizesService from '../services/velikost.service'
import categoryService from '../services/kategorie.service'
import typTextiluService from '../services/typ-textilu.service'
import ItemPagination from '../components/colors/ItemPagination'
import genericService from '../services/generic.service';

const TextilList = (props) => {

    const [textil, setTextil] = useState([]);
    const [filter, setFilter] = useState({})

    const [itemsCnt, setItemsCnt] = useState()
    const [pageNo, setPageNo] = useState(1);
    const perPageCnt = 20

    const [dodavatelOptionData, setDodavatelOptionData] = useState([]);
    const [dodavatelChosenData, setDodavatelChosenData] = useState([]);
    const [colorOptionData, setColorOptionData] = useState([]);
    const [colorChosenData, setColorChosenData] = useState([]);
    const [categoryOptionData, setCategoryOptionData] = useState([]);
    const [categoryChosenData, setCategoryChosenData] = useState([]);
    const [sizesOptionData, setSizesOptionData] = useState([]);
    const [sizesChosenData, setSizesChosenData] = useState([]);
    const [typeOptionData, setTypeOptionData] = useState([]);
    const [typeChosenData, setTypeChosenData] = useState([]);
    const [searchString, setSearchString] = useState();



    const getTextilFilter = useCallback(() => {
        let textilFilter = sessionStorage.Textilfilter ? sessionStorage.Textilfilter : '{}'
        let jsonFilter = JSON.parse(textilFilter)
        jsonFilter.pageNo = pageNo
        jsonFilter.perPageCnt = perPageCnt
        return jsonFilter
    }, [pageNo])



    const getButtonStatus = (code) => {
        const Textilfilter = JSON.parse(sessionStorage.Textilfilter ? sessionStorage.Textilfilter : '{}')
        let buttonStyle = ''

        switch (code) {
            case "skoly":
                buttonStyle = `btn ${Textilfilter?.zobrazit_skoly ? 'btn-info' : 'btn-default'} btn-xs`
                break;
            case "firmy":
                buttonStyle = `btn ${Textilfilter?.zobrazit_firmy ? 'btn-info' : 'btn-default'} btn-xs`
                break;
            default:
                break;
        }
        return buttonStyle

    }

    const [skolyButtonStatus, setSkolyButtonStatus] = useState(getButtonStatus('skoly'))
    const [firmyButtonStatus, setFirmyButtonStatus] = useState(getButtonStatus('firmy'))


    const filterSchoolTextil = () => {
        const Textilfilter = JSON.parse(sessionStorage.Textilfilter ? sessionStorage.Textilfilter : '{}')
        let schoolFilter = Textilfilter?.zobrazit_skoly
        if (schoolFilter === undefined)
            schoolFilter = false
        const filter = { ...Textilfilter, zobrazit_skoly: !schoolFilter }
        sessionStorage.setItem('Textilfilter', JSON.stringify(filter));
        setSkolyButtonStatus(getButtonStatus("skoly"))
        setFilter(filter)
        fetchTextil()
    }

    const filterCompanyTextil = () => {
        const Textilfilter = JSON.parse(sessionStorage.Textilfilter ? sessionStorage.Textilfilter : '{}')
        let companyFilter = Textilfilter?.zobrazit_firmy
        if (companyFilter === undefined)
            companyFilter = false
        const filter = { ...Textilfilter, zobrazit_firmy: !companyFilter }
        sessionStorage.setItem('Textilfilter', JSON.stringify(filter));
        setFirmyButtonStatus(getButtonStatus("firmy"))
        setFilter(filter)
    }

    const fetchCategory = useCallback(async () => {
        const textilFilter = getTextilFilter()
        setCategoryChosenData(textilFilter?.categoryFilter)

        const categoryData = await categoryService.getAllLight()
        let categoryOptions = []
        categoryData?.forEach(element => {
            const oneValue = { label: element.nazev, value: element.id, subCat: element.sub_kategories }
            categoryOptions.push(oneValue)
        })
        setCategoryOptionData(categoryOptions)

    }, [getTextilFilter])

    const fetchColors = useCallback(async () => {
        const textilFilter = getTextilFilter()

        setColorChosenData(textilFilter?.colorFilter)
        const colorData = await colorService.getAll()
        let colorOptions = []
        colorData?.data?.forEach(element => {
            const oneValue = { label: element.popis, value: element.id, color: element.rgb.split(',') }
            colorOptions.push(oneValue)
        })
        setColorOptionData(colorOptions)
    }, [getTextilFilter])

    const fetchSizes = useCallback(async () => {
        const textilFilter = getTextilFilter()
        setSizesChosenData(textilFilter?.sizesFilter)
        const sizesData = await sizesService.getAll()
        let sizesOptions = []
        sizesData?.forEach(element => {
            const oneValue = { label: element.nazev, value: element.id }
            sizesOptions.push(oneValue)
        })
        setSizesOptionData(sizesOptions)

    }, [getTextilFilter])

    const fetchTypes = useCallback(async () => {
        const textilFilter = getTextilFilter()
        setTypeChosenData(textilFilter?.typeFilter)
        const typeData = await typTextiluService.getAllLight()
        let typeOptions = []
        typeData?.data.forEach(element => {
            const oneValue = { label: element.popis, value: element.id }
            typeOptions.push(oneValue)
        })
        setTypeOptionData(typeOptions)

    }, [getTextilFilter])

    const fetchSuppliers = useCallback(async () => {
        const textilFilter = getTextilFilter()
        setDodavatelChosenData(textilFilter?.supplierFilter)
        const dodavatelData = await dodavatelService.getAll()
        let dodavatelOptions = []
        dodavatelData?.data.forEach(element => {
            const oneValue = { label: element.nazev, value: element.id }
            dodavatelOptions.push(oneValue)
        })
        setDodavatelOptionData(dodavatelOptions)
    }, [getTextilFilter])

    const fetchTextil = useCallback(async (pageNo) => {
        let textilFilter = getTextilFilter()
        textilFilter.pageNo = pageNo
        const textilData = await textilService.getAllTextil(textilFilter)
        setItemsCnt(textilData.cnt)
        setTextil(textilData.data);
        setSearchString(textilFilter.searchFilter)
        await fetchSuppliers()
        await fetchColors()
        await fetchCategory()
        await fetchSizes()
        await fetchTypes()
    }, [fetchCategory, fetchColors, fetchSizes, fetchSuppliers, fetchTypes, getTextilFilter]);


    const onChooseSupplier = (event) => {
        const Textilfilter = JSON.parse(sessionStorage.Textilfilter ? sessionStorage.Textilfilter : '{}')
        const filter = { ...Textilfilter, supplierFilter: event }
        sessionStorage.setItem('Textilfilter', JSON.stringify(filter));
        setDodavatelChosenData(event)
        setPageNo(1)
        setFilter(filter)
    }

    const onChooseColor = (event) => {
        const Textilfilter = JSON.parse(sessionStorage.Textilfilter ? sessionStorage.Textilfilter : '{}')
        const filter = { ...Textilfilter, colorFilter: event }
        sessionStorage.setItem('Textilfilter', JSON.stringify(filter));
        setColorChosenData(event)
        setPageNo(1)
        setFilter(filter)
    }

    const onChooseCategory = (event) => {

        const Textilfilter = getTextilFilter()
        const filter = { ...Textilfilter, categoryFilter: event }
        sessionStorage.setItem('Textilfilter', JSON.stringify(filter));
        setCategoryChosenData(event)
        setFilter(filter)
    }

    const onChooseSizes = (event) => {
        const Textilfilter = getTextilFilter()
        const filter = { ...Textilfilter, sizesFilter: event }
        sessionStorage.setItem('Textilfilter', JSON.stringify(filter));
        setSizesChosenData(event)
        setPageNo(1)
        setFilter(filter)
    }

    const onChooseType = (event) => {
        const Textilfilter = getTextilFilter()
        const filter = { ...Textilfilter, typeFilter: event }
        sessionStorage.setItem('Textilfilter', JSON.stringify(filter));
        setTypeChosenData(event)
        setPageNo(1)
        setFilter(filter)
    }

    const onSearchChange = (event) => {
        const Textilfilter = getTextilFilter()
        const searchValue = event.target.value;
        const filter = { ...Textilfilter, searchFilter: searchValue }
        sessionStorage.setItem('Textilfilter', JSON.stringify(filter));
        setSearchString(searchValue)
        setPageNo(1)
        setFilter(filter)
    }

    useEffect(() => {
        fetchTextil(pageNo);

    }, [fetchTextil, filter, pageNo]);

    // const styleDisplayNone = { display: "none" }

    return (
        <div className="wrapper wrapper-content animated fadeInRight ecommerce">


            <div className="ibox-content m-b-sm border-bottom">
                <div className="row">

                    <div className="col-sm-4">
                        <div className="form-group">
                            <label className="col-form-label" htmlFor="nazev">Název</label>
                            <input type="text" id="nazev" name="nazev" placeholder="" className="form-control" onChange={onSearchChange} value={searchString} />
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label className="col-form-label" htmlFor="supplier">Dodavatel</label>
                            <Select
                                closeMenuOnSelect={false}
                                defaultOptions={true}
                                value={dodavatelChosenData}
                                isMulti
                                name="supplier"
                                id="supplier"
                                options={dodavatelOptionData}
                                onChange={onChooseSupplier}
                            />
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label className="col-form-label" htmlFor="supplier">Barva</label>
                            <Select
                                closeMenuOnSelect={false}
                                defaultOptions={true}
                                value={colorChosenData}
                                isMulti
                                name="color"
                                id="color"
                                styles={genericService.getColourStyles()}
                                options={colorOptionData}
                                onChange={onChooseColor}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label className="col-form-label" htmlFor="category">Kategorie</label>
                            <Select
                                closeMenuOnSelect={false}
                                defaultOptions={true}
                                value={categoryChosenData}
                                isMulti
                                name="category"
                                id="category"
                                options={categoryOptionData}
                                onChange={onChooseCategory}
                            />
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label className="col-form-label" htmlFor="category">Velikost</label>
                            <Select
                                closeMenuOnSelect={false}
                                defaultOptions={true}
                                value={sizesChosenData}
                                isMulti
                                name="textilSize"
                                id="textilSize"
                                options={sizesOptionData}
                                onChange={onChooseSizes}
                            />
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label className="col-form-label" htmlFor="category">Typ Textilu</label>
                            <Select
                                closeMenuOnSelect={false}
                                defaultOptions={true}
                                value={typeChosenData}
                                isMulti
                                name="textilType"
                                id="textilType"
                                options={typeOptionData}
                                onChange={onChooseType}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2">
                        <div className="form-group ">
                            <label className=" col-form-label">Školní textil:</label>
                            <button className={skolyButtonStatus} type="button" id="zobrazit_skoly#button" onClick={filterSchoolTextil}>
                                <i id="zobrazit_skoly#i" className="fa fa-check"></i>
                            </button>
                        </div>

                    </div>
                    <div className="col-sm-2">
                        <div className="form-group ">
                            <label className=" col-form-label">Firemní textil:</label>
                            <button className={firmyButtonStatus} type="button" id="zobrazit_firmy#button" onClick={filterCompanyTextil}>
                                <i id="zobrazit_firmy#i" className="fa fa-check"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="ibox">
                        <div className="ibox-content">

                            <table className="footable table table-stripped toggle-arrow-tiny footable-loaded tablet breakpoint" data-page-size="15">
                                <thead>
                                    <tr>

                                        <th className="footable-visible footable-sortable footable-first-column">Kód<span className="footable-sort-indicator"></span></th>
                                        <th data-hide="phone" className="footable-visible footable-sortable">Název<span className="footable-sort-indicator"></span></th>
                                        <th data-hide="phone" className="footable-visible footable-sortable">Dodavatel<span className="footable-sort-indicator"></span></th>
                                        <th data-hide="phone" className="footable-visible footable-sortable">Prodejní cena<span className="footable-sort-indicator"></span></th>
                                        <th data-hide="phone,tablet" className="footable-visible footable-sortable">Změněno<span className="footable-sort-indicator"></span></th>
                                        <th className="text-right footable-visible footable-sortable footable-last-column">Akce<span className="footable-sort-indicator"></span></th>

                                    </tr>
                                </thead>
                                <tbody>

                                    {textil?.length > 0 && textil.map((oneTextil) => (
                                        <TextilListItem textil={oneTextil} key={oneTextil.id} />
                                    ))}



                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="7" className="footable-visible">
                                            <ItemPagination setPageNo={setPageNo} pageNo={pageNo} itemsCnt={itemsCnt} perPageNo={perPageCnt} />
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>

                        </div>
                    </div>
                </div>
            </div>


        </div>

    )
}

export default TextilList