const jwt = require("jsonwebtoken");
const config = require("../config/auth.config.js");

const verifyToken = (token) => {
    console.log("token")
    console.log(token)

    if (!token || token === "null" || token === undefined || token === null || token === "false") {
        return false

    } else {
        return true
    }
}

const authJwt = {
    verifyToken: verifyToken
};
module.exports = authJwt;