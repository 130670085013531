import React, { Fragment } from 'react';
import typBaleniService from '../../services/typ-baleni.service'
import ItemsList from '../colors/ItemsList';

const SizesList = (props) => {
    const listMetaData = {
        newLabel: 'Nový typ balení',
        headerLabel: 'Typ balení textilu'
    }

    const metaData = [
        {
            label: 'Kód',
            name: 'kod',
            dataName: 'kod',
            rowsize: 3,
            isReadOnly: true
        },
        {
            label: 'Název',
            name: 'popis',
            dataName: 'popis',
            rowsize: 8

        }
    ];

    return (
        <Fragment>
            <ItemsList itemService={typBaleniService} metaData={metaData} listMetaData={listMetaData} />
        </Fragment>
    )
}

export default SizesList