import React, { Fragment, useEffect, useState, useCallback } from "react";

import Select from 'react-select'
import chroma from 'chroma-js';

import textilService from '../services/textil.service'
import barvaService from '../services/barva.service'
import velikostService from '../services/velikost.service'
import typTextiluService from '../services/typ-textilu.service'
import typBaleniService from '../services/typ-baleni.service'
import kategorieService from '../services/kategorie.service'
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import dodavatelService from "../services/dodavatel.service";


const TextilInfoTab = (props) => {


    const isActiveTab = props.isActiveTab

    const checkActiveTab = () => {
        return (isActiveTab ? " active" : "")
    }

    const createNotification = (type) => {
        return () => {
            switch (type) {
                case 'info':
                    NotificationManager.info('Info message');
                    break;
                case 'success':
                    NotificationManager.success('Success message', 'Title here');
                    break;
                case 'warning':
                    NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
                    break;
                case 'error':
                    NotificationManager.error('Error message', 'Click me!', 5000, () => {
                        alert('callback');
                    });
                    break;
                default:
            }
        };
    };

    const [textil, setTextil] = useState([]);
    const [optionsData, setOptionsData] = useState({})
    const [isSaveInProgress, setIsSaveInProgress] = useState(false)


    const [formData, setFormData] = useState({})

    const rgbToHex = (rgb) => {
        let hex = Number(rgb).toString(16);

        if (hex.length < 2) {
            hex = "0" + hex;
        }
        return hex === "NaN" ? "00" : hex;
    };

    const fullColorHex = (rgb) => {
        var red = rgbToHex(rgb[0]);
        var green = rgbToHex(rgb[1]);
        var blue = rgbToHex(rgb[2]);
        return red + green + blue;
    };

    const colourStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const hexColor = "#" + fullColorHex(data.color)
            const color = chroma(hexColor);
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                        ? data.color
                        : isFocused
                            ? color.alpha(0.3).css()
                            : undefined,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? chroma.contrast(color, 'white') > 2
                            ? 'white'
                            : 'black'
                        : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? data.color
                            : color.alpha(0.3).css()
                        : undefined,
                },
            };
        },
        multiValue: (styles, { data }) => {
            const hexColor = "#" + fullColorHex(data.color)
            const color = chroma(hexColor);
            return {
                ...styles,
                backgroundColor: color.alpha(.5).css(),
            };
        },
        multiValueLabel: (styles) => {

            return {
                ...styles,
                color: "#000000",
            }
        },
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: data.color,
            ':hover': {
                backgroundColor: data.color,
                color: 'white',
            },
        }),
    };


    const fetchTextil = useCallback(async () => {
        console.log("start getTextil")

        // console.log("in fetchOneTextil")
        // console.log(params)
        const textilData = await textilService.getOneTextil(props.id)


        console.log("tempChosenColors")
        const textil = textilData.textil
        // setChosenColors(tempChosenColors)
        setTextil(textil);
        const initialFormData = {}
        if (textil) {
            initialFormData.id = textil.id
            initialFormData.kod = textil.kod
            initialFormData.nazev = textil.nazev
            initialFormData.material = textil.material
            initialFormData.specifikace = textil.specifikace
            initialFormData.prodejni_cena = textil.prodejni_cena
            initialFormData.cena_textilu = textil.cena_textilu
            initialFormData.minimalni_odber = textil.minimalni_odber
            // initialFormData.typTextilu = 
            // initialFormData.typBaleni = 
            // initialFormData.kategorie = 
            initialFormData.zobrazit_skoly = textil.zobrazit_skoly
            initialFormData.zobrazit_pataci = textil.zobrazit_pataci
            initialFormData.zobrazovatvtop_nabidce = textil.zobrazovatvtop_nabidce
            initialFormData.zobrazit_firmy = textil.zobrazit_firmy
            initialFormData.potiska2 = textil.potiska2


            setFormData(initialFormData)
        }
        return textilData
    }, [props.id]);


    const fetchBarvy = useCallback(async (textilData) => {
        const fetchedOptionsData = {
            barvyOptions: [],
            barvyChosen: [],
            sizesOptions: [],
            sizesChosen: [],
            typBaleniOptions: [],
            typBaleniChosen: [],
            typTextiluOptions: [],
            typTextiluChosen: [],
            kategorieOptions: [],
            kategorieChosen: [],
            dodavatelOptions: [],
            dodavatelChosen: []
        }

        const chosenColors = textilData.barvy.map(({ barva_id }) => {
            return barva_id;
        })
        const chosenSizes = textilData.velikosti.map(({ velikost_id }) => {
            return velikost_id;
        })

        const chosenBaleni = textilData.typBaleni.map(({ typ_baleni_id }) => {
            return typ_baleni_id;
        })

        const chosenTypTextilu = textilData.typTextilu.map(({ typ_textilu_id }) => {
            return typ_textilu_id;
        })

        const chosenKategorie = [textilData.textil.sub_kategorie_id]

        const chosenDodavatel = [textilData.textil.textil_dodavatels[0].dodavatel.id]


        const barvaData = await barvaService.getAll()
        const sizeData = await velikostService.getAll()
        const typTextiluData = await typTextiluService.getAll()
        const typBaleniData = await typBaleniService.getAll()
        const kategorieData = await kategorieService.getAll()
        const dodavatelData = await dodavatelService.getAll()

        barvaData?.data?.forEach(element => {
            const oneValue = { label: element.kod + " - " + element.popis, value: element.id, color: element.rgb.split(',') }
            if (chosenColors.includes(parseInt(element.id)))
                fetchedOptionsData.barvyChosen.push(oneValue)
            fetchedOptionsData.barvyOptions.push(oneValue)
        })


        sizeData.forEach(element => {
            const oneValue = { label: element.nazev, value: element.id }
            if (chosenSizes.includes(parseInt(element.id)))
                fetchedOptionsData.sizesChosen.push(oneValue)
            fetchedOptionsData.sizesOptions.push(oneValue)
        })

        typBaleniData?.data?.forEach(element => {
            const oneValue = { label: element.popis, value: element.id }
            if (chosenBaleni.includes(parseInt(element.id)))
                fetchedOptionsData.typBaleniChosen.push(oneValue)
            fetchedOptionsData.typBaleniOptions.push(oneValue)
        })

        typTextiluData?.data?.forEach(element => {
            const oneValue = { label: element.popis, value: element.id }
            if (chosenTypTextilu.includes(parseInt(element.id)))
                fetchedOptionsData.typTextiluChosen.push(oneValue)
            fetchedOptionsData.typTextiluOptions.push(oneValue)
        })

        kategorieData.forEach(kategorie => {
            kategorie.sub_kategories.forEach(element => {
                const oneValue = { label: kategorie.nazev + " - " + element.nazev, value: element.id }
                if (chosenKategorie.includes(parseInt(element.id)))
                    fetchedOptionsData.kategorieChosen.push(oneValue)
                fetchedOptionsData.kategorieOptions.push(oneValue)
            })
        })

        dodavatelData?.data?.forEach(element => {
            const oneValue = { label: element.nazev, value: element.id }
            if (chosenDodavatel.includes(parseInt(element.id)))
                fetchedOptionsData.dodavatelChosen.push(oneValue)
            fetchedOptionsData.dodavatelOptions.push(oneValue)
        })

        setOptionsData(fetchedOptionsData)

        console.log("end fetchBarvy")
        return true;
    }, []);

    const fetchData = useCallback(async () => {
        console.log("in Fetch Data")
        const textils = await fetchTextil()
        await fetchBarvy(textils)
    }, [fetchBarvy, fetchTextil])


    useEffect(() => {
        fetchData();
    }, [fetchData]);


    const inputChangeHandler = (event) => {
        const id = event.target.id.split("#")[0]
        setFormData({ ...formData, [id]: event.target.value })
    }

    const buttonChangeHandler = (event) => {
        const id = event.target.id.split("#")[0]
        const value = formData[id]
        let newValue = !value
        if (value === 0)
            newValue = 1
        if (value === 1)
            newValue = 0
        setFormData({ ...formData, [id]: newValue })
    }


    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsSaveInProgress(true)
        const submittedFormData = { ...formData }
        submittedFormData.barvy = optionsData.barvyChosen
        submittedFormData.velikosti = optionsData.sizesChosen
        submittedFormData.typBaleni = optionsData.typBaleniChosen
        submittedFormData.typTextilu = optionsData.typTextiluChosen
        submittedFormData.kategorie = optionsData.kategorieChosen
        submittedFormData.dodavatel = optionsData.dodavatelChosen

        await textilService.save(submittedFormData)

        const visibility = props.visibility
        props.setVisibilityChange(!visibility)
        props.setVisibility(!visibility)
        createNotification("info")
        setIsSaveInProgress(false)

    }

    const onColorChangeHandler = (event) => {
        setOptionsData({ ...optionsData, barvyChosen: event })
    }

    const onSizeChangeHandler = (event) => {
        setOptionsData({ ...optionsData, sizesChosen: event })
    }

    const onTypBaleniChangeHandler = (event) => {
        setOptionsData({ ...optionsData, typBaleniChosen: event })
    }

    const onTypTextiluChangeHandler = (event) => {
        setOptionsData({ ...optionsData, typTextiluChosen: event })
    }
    const onKategorieChangeHandler = (event) => {
        setOptionsData({ ...optionsData, kategorieChosen: event })
    }
    const onDodavatelChangeHandler = (event) => {
        setOptionsData({ ...optionsData, dodavatelChosen: event })
    }


    return (
        <Fragment>
            <div id="tab-1" className={`tab-pane ${checkActiveTab()}`}>
                <form onSubmit={handleSubmit} id="tab1form">
                    <input type="hidden" id="id" name="id" defaultValue={formData?.id} />
                    <div className="panel-body">
                        <fieldset>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Kód:</label>
                                <div className="col-sm-1"><input type="text" className="form-control" id="kod" name="kod" defaultValue={formData?.kod} onChange={inputChangeHandler} /></div>
                                <label className="col-sm-1 col-form-label">Název:</label>
                                <div className="col-sm-5"><input type="text" className="form-control" id="nazev" name="nazev" defaultValue={formData?.nazev} onChange={inputChangeHandler} /></div>
                                <label className="col-sm-1 col-form-label">Materiál:</label>
                                <div className="col-sm-2"><input type="text" className="form-control" id="material" name="material" defaultValue={formData?.material} onChange={inputChangeHandler} /></div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Cena školy:</label>
                                <div className="col-sm-2"><input type="text" className="form-control" placeholder="0.00" id="prodejni_cena" name="prodejni_cena" defaultValue={formData?.prodejni_cena} onChange={inputChangeHandler} /></div>
                                <label className="col-sm-2 col-form-label">Cena textil:</label>
                                <div className="col-sm-2"><input type="text" className="form-control" placeholder="0.00" id="cena_textilu" name="cena_textilu" defaultValue={formData?.cena_textilu} onChange={inputChangeHandler} /></div>
                                <label className="col-sm-2 col-form-label">Minimální odběr:</label>
                                <div className="col-sm-2"><input type="text" className="form-control" placeholder="0.00" id="minimalni_odber" name="minimalni_odber" defaultValue={formData?.minimalni_odber} onChange={inputChangeHandler} /></div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">specifikace:</label>
                                <div className="col-sm-10">
                                    <textarea className="note-editable card-block border rounded-lg form-control " aria-multiline="true" spellCheck="false" id="specifikace" name="specifikace" defaultValue={formData?.specifikace} rows="8" onChange={inputChangeHandler} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Barvy:</label>
                                <div className="col-sm-5">

                                    <Select
                                        closeMenuOnSelect={false}
                                        defaultOptions={true}
                                        value={optionsData.barvyChosen}
                                        isMulti
                                        name="colors"
                                        id="colors-picker"
                                        options={optionsData.barvyOptions}
                                        styles={colourStyles}
                                        onChange={onColorChangeHandler}
                                    />
                                </div>

                                <label className="col-sm-1 col-form-label">Velikosti:</label>
                                <div className="col-sm-4">
                                    <Select
                                        closeMenuOnSelect={false}
                                        defaultOptions={true}
                                        value={optionsData.sizesChosen}
                                        isMulti
                                        name="sizes"
                                        id="sizes-picker"
                                        options={optionsData.sizesOptions}
                                        onChange={onSizeChangeHandler}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Typ Textilu:</label>
                                <div className="col-sm-4">
                                    <Select
                                        closeMenuOnSelect={false}
                                        defaultOptions={true}
                                        value={optionsData.typTextiluChosen}
                                        isMulti
                                        name="typ-textilu"
                                        id="typ-textilu-picker"
                                        options={optionsData.typTextiluOptions}
                                        onChange={onTypTextiluChangeHandler}
                                    />
                                </div>
                                <label className="col-sm-2 col-form-label">Typ Balení:</label>
                                <div className="col-sm-4">
                                    <Select
                                        closeMenuOnSelect={false}
                                        defaultOptions={true}
                                        value={optionsData.typBaleniChosen}
                                        isMulti
                                        name="typ-baleni"
                                        id="typ-baleni-picker"
                                        options={optionsData.typBaleniOptions}
                                        onChange={onTypBaleniChangeHandler}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Kategorie:</label>
                                <div className="col-sm-4">
                                    <Select
                                        closeMenuOnSelect={false}
                                        defaultOptions={true}
                                        value={optionsData.kategorieChosen}
                                        name="kategorie"
                                        id="kategorie-picker"
                                        options={optionsData.kategorieOptions}
                                        onChange={onKategorieChangeHandler}
                                    />
                                </div>
                                <label className="col-sm-2 col-form-label">Dodavatel:</label>
                                <div className="col-sm-4">
                                    <Select
                                        closeMenuOnSelect={false}
                                        defaultOptions={true}
                                        value={optionsData.dodavatelChosen}
                                        name="dodavatel"
                                        id="dodavatel-picker"
                                        options={optionsData.dodavatelOptions}
                                        onChange={onDodavatelChangeHandler}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Zobrazit Školy:</label>
                                <div className="col-sm-1"><button className={`btn ${formData.zobrazit_skoly === true ? "btn-info" : "btn-default"}  btn-xs`} type="button" id="zobrazit_skoly#button" onClick={buttonChangeHandler}><i id="zobrazit_skoly#i" className="fa fa-check"></i></button> </div>
                            </div>
                            <div className="form-group row">

                                <label className="col-sm-2 col-form-label">Zobrazit Firmy:</label>
                                <div className="col-sm-1"><button className={`btn ${formData.zobrazit_firmy === true ? "btn-info" : "btn-default"}  btn-xs`} type="button" id="zobrazit_firmy#button" onClick={buttonChangeHandler}><i id="zobrazit_firmy#i" className="fa fa-check"></i></button>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Top Nabídka:</label>
                                <div className="col-sm-1"><button className={`btn ${formData.zobrazovatvtop_nabidce === true ? "btn-info" : "btn-default"}  btn-xs`} type="button" id="zobrazovatvtop_nabidce#button" onClick={buttonChangeHandler}><i id="zobrazovatvtop_nabidce#i" className="fa fa-check"></i></button>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col">
                                    <table align="right" >
                                        <tbody>
                                            <tr>
                                                <td> {isSaveInProgress && <div className="sk-spinner sk-spinner-circle">
                                                    <div className="sk-circle1 sk-circle">s</div>
                                                    <div className="sk-circle2 sk-circle"></div>
                                                    <div className="sk-circle3 sk-circle"></div>
                                                    <div className="sk-circle4 sk-circle"></div>
                                                    <div className="sk-circle5 sk-circle"></div>
                                                    <div className="sk-circle6 sk-circle"></div>
                                                    <div className="sk-circle7 sk-circle"></div>
                                                    <div className="sk-circle8 sk-circle"></div>
                                                    <div className="sk-circle9 sk-circle"></div>
                                                    <div className="sk-circle10 sk-circle"></div>
                                                    <div className="sk-circle11 sk-circle"></div>
                                                    <div className="sk-circle12 sk-circle"></div>
                                                </div>

                                                }</td>
                                                <td>
                                                    <button className="btn btn-primary " type="submit" disabled={isSaveInProgress}>
                                                        <i className="fa fa-check" ></i>
                                                        &nbsp;Ulozit</button>
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </fieldset>
                    </div>
                </form>
            </div >

        </Fragment >
    )
};

export default TextilInfoTab;
