import React  from 'react';

const RgbRect = (props) => {

    const rgb = props.rgb
    const size = props.size

    const getRgbRectangle = (rgb, size) => {
        return {
            zIndex: 1,
            width: size + 'px',
            height: size + 'px',
            borderColor: 'black',
            border: 'solid 1px',
            backgroundColor: 'rgb(' + rgb + ')',
            visibility: 'visible',
        };
    };

    return (
        <div id="colorRect" style={getRgbRectangle(rgb, size)} ></div>
    )
}

export default RgbRect