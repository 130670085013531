import React, { Fragment } from 'react';
import printColorService from '../../services/print-color.service'
import ItemsList from '../colors/ItemsList';

const printColorsList = (props) => {
    const listMetaData = {
        newLabel: 'Nová barva potisku',
        headerLabel: 'Barva potisku'
    }

    const metaData = [
        {
            label: 'Kód',
            name: 'kod',
            dataName: 'kod',
            rowsize: 3,
            isReadOnly: true
        },
        {
            label: 'Název',
            name: 'popis',
            dataName: 'popis',
            rowsize: 8

        },
        {
            label: 'Cena',
            name: 'cena',
            dataName: 'cena',
            rowsize: 3

        },
        {
            label: 'Barva',
            name: 'barva',
            dataName: 'rgb',
            dataType: 'rgb',

        },
        {
            label: 'RGB',
            name: 'rgb',
            dataName: 'rgb',
            rowsize: 4
        }
    ];

    return (
        <Fragment>
            <ItemsList itemService={printColorService} metaData={metaData} listMetaData={listMetaData} />
        </Fragment>
    )
}

export default printColorsList

/*
"id":1,
"cena":0,
"kod":"01",
"obrazek":{
   "type":"Buffer",
   "data":[
      
   ]
},
"popis":"Bílá",
"rgb":"255,255,255",
"cena_rukav":null,
"cena_rukava5":0,
"cena_rukava6":0,
"cena_trickoa2":0,
"a2cena_tricko":0,
"a3cena_tricko":0,
"a6cena_rukav":0

*/