import React, { Fragment, useEffect, useState } from 'react';

const OrderDetailGoodsItem = (props) => {

    const [itemData, setItemData] = useState()

    const changeOrderGoods = props?.changeOrderGoods


    const onDataChange = (event) => {
        var data = itemData
        data[event.target.id] = parseInt(event.target.value)
        setItemData(data)
        changeOrderGoods(data)
     }


    useEffect(() => {
        const oneMaterial = props.oneMaterial      
        setItemData(oneMaterial)
    }, [props]);


    const textAlignRight = { "textAlign": "right", "width": "15%", "valign": "middle" }
    const textAlignLeft = { "textAlign": "left", "width": "60%", "valign": "middle" }
    return (
        <Fragment>
            <tr>
                <td style={textAlignLeft}><div><strong>{itemData?.label}</strong></div></td>
                <td style={textAlignRight}>
                    <div className="input-group">
                        <input placeholder="mnozstvi" id="quantity" name="quantity" className="form-control small" value={itemData?.quantity} size="1" style={textAlignRight} onChange={onDataChange} />
                        <div className="input-group-append">
                            <span className="input-group-addon">ks</span>
                        </div>
                    </div>
                </td>
                <td style={textAlignRight}>
                    <div className="input-group">
                        <input placeholder="cena" id="price" name="price" className="form-control small" value={itemData?.price} size="1" style={textAlignRight} onChange={onDataChange} />
                        <div className="input-group-append">
                            <span className="input-group-addon">Kč</span>
                        </div>
                    </div>
                </td>
                <td style={textAlignRight}>{itemData?.quantity * itemData?.price} Kč</td>
            </tr>

        </Fragment>
    )
}

export default OrderDetailGoodsItem