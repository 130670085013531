import React, { useEffect, useState } from "react";
import { BASE_API_URL } from '../constants';

import textilObrazekService from "../services/textil-obrazek.service"
import MissingColor from "./MissingColor";

const PicturesTab = (props) => {

    const [pictures, setPictures] = useState([])
    const [missingColors, setMissingColors] = useState([])
    const [change, setChange] = useState(false)

    const isActiveTab = props.isActiveTab

    const checkActiveTab = () => {
        return (isActiveTab ? " active" : "")
    }

    async function setPictureFirst(id) {
        await textilObrazekService.setPictureFirst(props.id, id)
        setChange(!change)

    }

    async function deletePicture(id) {
        await textilObrazekService.deletePicture(id)
        setChange(!change)
    }

    useEffect(() => {
        const loadData = async () => {
            console.log("in LoadData")
            const pictures = await textilObrazekService.getTextilPrictures(props.id)
            setPictures(pictures.data)
            setMissingColors(pictures.missingColors)
        }
        console.log("loadData")
        loadData()
    }, [change, props.id]);

    return (
        <div id="tab-4" className={`tab-pane ${checkActiveTab()}`}>

            <div className="wrapper wrapper-content animated fadeInRight">
                {(missingColors !== undefined && missingColors.length > 0) && <div className="ibox">
                    <div className="ibox-title">
                        <h5>Chybějící obrázky</h5>
                    </div>
                    <div className="ibox-content">

                        <div className="row">
                            {missingColors.map((picture) => (
                                <MissingColor picture={picture} textilId={props.id} setChange={setChange} change={change} />
                            ))}
                        </div>
                    </div>
                </div >}
                <div className="ibox">
                    <div className="ibox-title">
                        <h5>Obrázky</h5>
                    </div>
                    <div className="ibox-content">
                        <div className="row">
                            {pictures != undefined && pictures.map((picture) => (
                                <div className="col-md-3" key={picture.id}>
                                    <div className="ibox">
                                        <div className="ibox-content product-box">
                                            <div className="product-imitation">
                                                <img src={`${BASE_API_URL}/textil-obrazek/picture/${picture.id}`} width={"320px"} />
                                            </div>
                                            <div className="product-desc">
                                                <span className="product-price">
                                                    {`${picture.kod}`}
                                                </span>
                                                <a href="#" className="product-name"> {`${picture.color.popis}`} </a>
                                                {picture.poradi !== 1 && <button className="btn btn-xs btn-outline btn-primary" onClick={() => setPictureFirst(picture.id)}> <i className="fa fa-long-arrow-up"></i> </button>} &nbsp;
                                                <button className="btn btn-xs btn-outline btn-danger" onClick={() => deletePicture(picture.id)}> <i className="fa fa-trash"></i> </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div >
            </div >
        </div>
    )
}

export default PicturesTab