import React from "react";
import { Redirect, Route } from "react-router-dom";
// import Layout from "../layout/Layout";
import Layout from "../components/layout/Layout";

const ProtectedRoute = ({ path, exact, children }) => {
    const auth = require("./authJwt");
    const token = sessionStorage.getItem("accessToken");


    return auth.verifyToken(token) ? (
        <Layout>
            <Route path={path} exact={exact}>
                {children}
            </Route>
        </Layout>
    ) : (
        <Redirect to="/login" />
    );
};

export default ProtectedRoute;