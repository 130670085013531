import React, { Fragment, useEffect, useState } from 'react';
import OrderDetailGoodsItem from './OrderDetailGoodsItem';

const OrderDetailGoods = (props) => {

    const [totalPrice, setTotalPrice] = useState(0)

    const convertOrderGoods = (inData) => {
        var basketGoods = {}
        var totalPrice = 0
        for (const val of inData) {
            basketGoods[val.id_objednavka_zbozi] = {
                id: val.id_objednavka_zbozi,
                label: val.nazev_zbozi,
                price: val.cena,
                color: val.barva,
                quantity: val.mnozstvi
            }
            totalPrice += val.cena * val.mnozstvi
        }
        setTotalPrice(totalPrice)

        // Object.keys(basketGoods).map(function(keyName, keyIndex) {
        //     console.log(basketGoods[keyName])
        //   })

        return basketGoods
    }


    const [orderGoods, setOrderGoods] = useState(undefined)

    const textAlignRight = { "textAlign": "right" }
    const alignRight = { "textAlign": "right" }



    const changeOrderGoods = (goods) => {
        // var orderGoodsTmp = orderGoods
        // orderGoodsTmp[goods.id] = goods
        // setCartChanged(!cartChanged)
        // setOrderGoods(orderGoodsTmp)
    }

    useEffect(() => {
        if (orderGoods === undefined)
            setOrderGoods(convertOrderGoods(props?.orderGoods))
    }, [orderGoods, props?.orderGoods]);

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-9">

                    <div className="ibox">
                        <div className="ibox-title">
                            <span className="float-right">(<strong>5</strong>) items</span>
                            <h5>Items in your cart</h5>
                        </div>
                        <div className="ibox-content">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Zboží</th>
                                        <th style={textAlignRight}>Množství</th>
                                        <th style={textAlignRight}>Cena za ks</th>
                                        <th style={textAlignRight}>Celková cena</th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    {/* {console.log(orderGoods)} */}
                                    {/* {console.log(Math.round(+new Date()))} */}
                                    { (orderGoods !== undefined) &&  Object.keys(orderGoods).map(keyName => (                                       
                                        <OrderDetailGoodsItem oneMaterial={orderGoods[keyName]} key={`orderItem#${keyName}`} changeOrderGoods={changeOrderGoods} />
                                    ))}
                                 </tbody>
                            </table>
                        </div>
                        <div className="ibox-content" style={alignRight}>
                            <div className="btn-group" >
                                <a href="#0" className="btn btn-primary btn-sm"><i className="fa fa-shopping-cart"></i> Uložit</a>
                                <a href="#0" className="btn btn-white btn-sm"> Zrušit</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">

                    <div className="ibox">
                        <div className="ibox-title">
                            <h5>Shrnutí</h5>
                        </div>
                        <div className="ibox-content">
                            <span>
                                Celková cena
                            </span>
                            <h2 className="font-bold">
                                {totalPrice} Kč
                            </h2>

                            <hr />
                            <span className="text-muted small">
                                *For United States, France and Germany applicable sales tax will be applied
                            </span>
                            <div className="m-t-sm" style={alignRight}>
                                <div className="btn-group"  >
                                    <a href="#0" className="btn btn-primary btn-sm"><i className="fa fa-shopping-cart"></i> Uložit</a>
                                    <a href="#0" className="btn btn-white btn-sm"> Zrušit</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="ibox">
                        <div className="ibox-title">
                            <h5>Support</h5>
                        </div>
                        <div className="ibox-content text-center">



                            <h3><i className="fa fa-phone"></i> +43 100 783 001</h3>
                            <span className="small">
                                Please contact with us if you have any questions. We are avalible 24h.
                            </span>


                        </div>
                    </div>


                </div>
            </div>
        </Fragment>
    )
}

export default OrderDetailGoods