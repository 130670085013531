import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";

import FiremniCenyTab from "./FiremniCenyTab";
import SkolniCenyTab from "./SkolniCenyTab"
import TextilInfoTab from "./TextilInfoTab";
import PicturesTab from "./PicturesTab";

import textilService from '../services/textil.service'

const TextilDetail = (props) => {

  const params = useParams();
  const textilId = params.id

  const [visibilityChange, setVisibilityChange] = useState(false)
  const [visibility, setVisibility] = useState(false)
  const [activeTab, setActiveTab] = useState({});
  const [isSchoolPricesVisible, setIsSchoolPricesVisible] = useState(false)
  const [isCompanyPricesVisible, setIsCompanyPricesVisible] = useState(false)


  const isActiveTab = (chosenTab) => {
    return (chosenTab === activeTab)
  }

  const getActiveTabClass = (chosenTab) => {
    return (chosenTab === activeTab ? "nav-link active" : "nav-link")
  }



  useEffect(() => {

    const loadData = async () => {
      const visibleTabs = await textilService.getTextilTabs(textilId)
      setActiveTab('textilInfo');
      setIsSchoolPricesVisible(visibleTabs.zobrazit_skoly)
      setIsCompanyPricesVisible(visibleTabs.zobrazit_firmy)
    }
    loadData()
  }, [textilId, visibilityChange]);

  return (
    <Fragment>
      <div className="wrapper wrapper-content animated fadeInRight ecommerce">
        <div className="row">
          <div className="col-lg-12">
            <div className="tabs-container">
              <ul className="nav nav-tabs">
                <li>
                  <button className={`${getActiveTabClass('textilInfo')}`} data-toggle="tab" onClick={() => setActiveTab('textilInfo')} >Textil info</button>
                </li>
                {isCompanyPricesVisible && <li>
                  <button className={`${getActiveTabClass('companyPrices')}`} data-toggle="tab" onClick={() => setActiveTab('companyPrices')}>Firemní ceny</button>
                </li>}
                {isSchoolPricesVisible && <li>
                  <button className={`${getActiveTabClass('schoolPrices')}`} data-toggle="tab" onClick={() => setActiveTab('schoolPrices')}>Školní ceny</button>
                </li>}
                <li>
                  <button className={`${getActiveTabClass('textilPictures')}`} data-toggle="tab" onClick={() => setActiveTab('textilPictures')}>Obrázky</button>
                </li>
              </ul>
              <div className="tab-content">
                <div className="ibox">
                  {isActiveTab('textilInfo') && <TextilInfoTab id={params.id} isActiveTab={isActiveTab('textilInfo')} setVisibilityChange={setVisibilityChange} visibility={visibility} setVisibility={setVisibility}></TextilInfoTab>}
                  {isActiveTab('companyPrices') && isCompanyPricesVisible && <FiremniCenyTab id={params.id} isActiveTab={isActiveTab('companyPrices')} ></FiremniCenyTab>}
                  {isActiveTab('schoolPrices') && isSchoolPricesVisible && <SkolniCenyTab id={params.id} isActiveTab={isActiveTab('schoolPrices')} ></SkolniCenyTab>}
                  {isActiveTab('textilPictures') && <PicturesTab id={params.id} isActiveTab={isActiveTab('textilPictures')} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment >
  );
};

export default TextilDetail;
