import React, { Fragment, useEffect, useState, useCallback } from 'react';
import ordersService from '../../services/orders.service';
import { useParams } from "react-router";
import genericService from '../../services/generic.service';
import OrderDetailCustomer from './OrderDetailCustomer';
import OrderDetailGoods from './OrderDetailGoods';

const OrderDetail = (props) => {
    const [orderDetailData, setOrderDetailData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [customerAddresses, setCustomerAddresses] = useState([]);
    const [customerContacts, setCustomerContacts] = useState([]);
    const [orderFiles, setOrderFiles] = useState([]);
    const [orderGoods, setOrderGoods] = useState()
    const [isCustomerActiveTab, setIsCustomerActiveTab] = useState(true)

    const params = useParams();

    const orderId = params.id



    const getFileTypeLabel = (orderFile, orderDetailData) => {
        var label = ''
        switch (orderFile.typ_souboru) {
            case 48:
                label = "Pracovní"
                if (orderDetailData.faktura_vystavena && orderFile.nazev.startsWith('faktura'))
                    label = "Faktura"
                break;
            case 47:
                label = "Náhled"
                break;
            case 658:
                label = "Originál"
                break;
            default:
                break;
        }
        return label
    }

    const getEmail = (contacts) => {
        const contact = contacts?.filter((contact) => {
            return contact.id_kontakt_typ === 44
        })

        return contact[0]?.kontakt

    }

    const getPhone = (contacts) => {
        const contact = contacts?.filter((contact) => {
            return contact.id_kontakt_typ === 45
        })

        return contact[0]?.kontakt
    }

    const fetchData = useCallback(async (orderId) => {

        const orderDetailData = await ordersService.getOne(orderId)
        const orderData = orderDetailData.data
        const customer = orderData.id_zakaznika_zakaznik
        const addresses = customer.adresas
        const constacts = customer.kontakts
        const orderFiles = orderData.soubory_objednavkies
        const orderGoodsData = orderData.objednavka_zbozis

        setOrderDetailData(orderData)
        setCustomerData(customer)
        setCustomerAddresses(addresses)
        setCustomerContacts(constacts)
        setOrderFiles(orderFiles)
        setOrderGoods(orderGoodsData)
    }, []);

    useEffect(() => {
        fetchData(orderId)
    }, [fetchData, orderId, props]);

    return (
        <Fragment>
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-sm-4">
                    <h2>Project detail</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="index.html">Home</a>
                        </li>
                        <li className="breadcrumb-item active">
                            <strong>Project detail</strong>
                        </li>
                    </ol>
                </div>
            </div>
            <div className="wrapper wrapper-content animated fadeInUp">
                <div className="row">
                    <div className="col-lg-9">
                        <div className="ibox">
                            <div className="ibox-content">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="m-b-md">
                                            <a href="#0" className="btn btn-white btn-xs float-right">Edit project</a>

                                            <h2>Objednávka {orderDetailData.id_objednavky}</h2>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div className="contact-box left-version">

                                            <dl className="row mb-0">
                                                <div className="col-sm-4 text-sm-right">
                                                    <dt>Stav:</dt>
                                                </div>
                                                <div className="col-sm-8 text-sm-left">
                                                    <dd className="mb-1"><span className="label label-primary">Aktivní</span></dd>
                                                </div>
                                            </dl>
                                            <dl className="row mb-0">
                                                <div className="col-sm-4 text-sm-right">
                                                    <dt>Zákaznik:</dt>
                                                </div>
                                                <div className="col-sm-8 text-sm-left">
                                                    <dd className="mb-1">{genericService.nvl(customerData.jmeno + ' ' + customerData.prijmeni) + genericService.nvlSep(customerData.nazev_firmy) + genericService.nvlSep(customerData.mesto)} </dd>
                                                </div>
                                            </dl>
                                            <dl className="row mb-0">
                                                <div className="col-sm-4 text-sm-right">
                                                    <dt>Provize pro:</dt>
                                                </div>
                                                <div className="col-sm-8 text-sm-left">
                                                    <dd className="mb-1">{genericService.nvl(orderDetailData?.id_user_user?.jmeno + ' ' + orderDetailData?.id_user_user?.prijmeni)}&nbsp;<button type="button" className="btn btn-xs btn-white"> <i className="fa fa-pencil"></i> </button></dd>
                                                </div>
                                            </dl>
                                            <dl className="row mb-0">
                                                <div className="col-sm-4 text-sm-right">
                                                    <dt>Číslo objednávky:</dt>
                                                </div>
                                                <div className="col-sm-8 text-sm-left">
                                                    <dd className="mb-1">{orderDetailData?.cislo_objednavky}</dd>
                                                </div>
                                            </dl>
                                            <dl className="row mb-0">
                                                <div className="col-sm-4 text-sm-right">
                                                    <dt>Přidělené číslo:</dt>
                                                </div>
                                                <div className="col-sm-8 text-sm-left">
                                                    <dd className="mb-1">{orderDetailData?.pridelene_cislo_objednavky}&nbsp;<button type="button" className="btn btn-xs btn-white"> <i className="fa fa-pencil"></i> </button></dd>
                                                </div>
                                            </dl>
                                            <dl className="row mb-0">
                                                <div className="col-sm-4 text-sm-right">
                                                    <dt>Externí číslo:</dt>
                                                </div>
                                                <div className="col-sm-8 text-sm-left">
                                                    <dd className="mb-1"> {orderDetailData?.externi_cislo_objednavky}</dd>
                                                </div>
                                            </dl>

                                            <dl className="row mb-0">
                                                <div className="col-sm-4 text-sm-right">
                                                    <dt>Datum zadání:</dt>
                                                </div>
                                                <div className="col-sm-8 text-sm-left">
                                                    <dd className="mb-1">{orderDetailData?.datum_zadani_objednavky}</dd>
                                                </div>
                                            </dl>
                                            <dl className="row mb-0">
                                                <div className="col-sm-4 text-sm-right">
                                                    <dt>Datum dodání:</dt>
                                                </div>
                                                <div className="col-sm-8 text-sm-left">
                                                    <dd className="mb-1">{orderDetailData?.datum_dodani_objednavky}&nbsp;<button type="button" className="btn btn-xs btn-white"> <i className="fa fa-pencil"></i> </button></dd>
                                                </div>
                                            </dl>

                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="contact-box left-version">
                                            <a href="#0">
                                                <h3 className="m-b-xs"><strong>{customerData.jmeno + ' ' + customerData.prijmeni}</strong></h3>
                                                <div className="font-bold">{customerData.nazev_firmy}</div>
                                                <address className="m-t-md">
                                                    {customerAddresses[0]?.adresa_ulice}<br />
                                                    {customerAddresses[0]?.adresa_mesto}, {customerAddresses[0]?.adresa_psc} <br />
                                                    <abbr title="Telefon"><i className="fa fa-phone"> </i></abbr> {getPhone(customerContacts)}<br />
                                                    <abbr title="Email"><i className="fa fa-envelope"> </i></abbr> {getEmail(customerContacts)}
                                                </address>

                                            </a>
                                            {/* <div className="contact-box-footer">
                                    <div className="m-t-xs btn-group">
                                        <a ref={() => false} className="btn btn-xs btn-white"><i className="fa fa-phone"></i> Call </a>
                                        <a ref={() => false} className="btn btn-xs btn-white"><i className="fa fa-envelope"></i> Email</a>
                                        <a ref={() => false} className="btn btn-xs btn-white"><i className="fa fa-user-plus"></i> Follow</a>
                                    </div>
                                </div> */}

                                        </div>

                                    </div>
                                    <div className="col-lg-3" id="cluster_info">
                                        d
                                        {/* <dl className="row mb-0">
    <div className="col-sm-4 text-sm-right">
        <dt>Participants:</dt>
    </div>
    <div className="col-sm-8 text-sm-left">
        <dd className="project-people mb-1">
            <a ref={() => false}><img alt="image" className="rounded-circle" src="img/a3.jpg" /></a>
            <a ref={() => false}><img alt="image" className="rounded-circle" src="img/a1.jpg" /></a>
            <a ref={() => false}><img alt="image" className="rounded-circle" src="img/a2.jpg" /></a>
            <a ref={() => false}><img alt="image" className="rounded-circle" src="img/a4.jpg" /></a>
            <a ref={() => false}><img alt="image" className="rounded-circle" src="img/a5.jpg" /></a>
        </dd>
    </div>
</dl> */}
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-lg-12">
                                        <dl className="row mb-0">
                                            <div className="col-sm-2 text-sm-right">
                                                <dt>Completed:</dt>
                                            </div>
                                            <div className="col-sm-10 text-sm-left">
                                                <dd>
                                                    <div className="progress m-b-1">
                                                        <div style={sixtyPercent} className="progress-bar progress-bar-striped progress-bar-animated"></div>
                                                    </div>
                                                    <small>Project completed in <strong>60%</strong>. Remaining close the project, sign a contract and invoice.</small>
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div> */}
                                <div className="row m-t-sm">
                                    <div className="col-lg-12">
                                        <div className="panel blank-panel">
                                            <div className="panel-heading">
                                                <div className="panel-options">
                                                    <ul className="nav nav-tabs">
                                                        <li><a className="nav-link" href="#tab-1" data-toggle="tab">Info</a></li>
                                                        <li><a className="nav-link active" href="#tab-customer" data-toggle="tab">Zákaznik</a></li>
                                                        <li><a className="nav-link" href="#tab-items" data-toggle="tab">Zboží</a></li>
                                                        <li><a className="nav-link" href="#tab-motives" data-toggle="tab">Motivy</a></li>
                                                        <li><a className="nav-link" href="#tab-history" data-toggle="tab">Historie</a></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="panel-body">

                                                <div className="tab-content">
                                                    <div className="tab-pane" id="tab-1">
                                                        <div className="feed-activity-list">
                                                            <div className="feed-element">
                                                                <a href="#0" className="float-left">
                                                                    <img alt="" className="rounded-circle" src="img/a2.jpg" />
                                                                </a>

                                                                <div className="media-body ">
                                                                    <small className="float-right">2h ago</small>
                                                                    <strong>Mark Johnson</strong> posted message on <strong>Monica Smith</strong> site. <br />
                                                                    <small className="text-muted">Today 2:10 pm - 12.06.2014</small>
                                                                    <div className="well">
                                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                                                        Over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="feed-element">
                                                                <a href="#0" className="float-left">
                                                                    <img alt="" className="rounded-circle" src="img/a3.jpg" />
                                                                </a>

                                                                <div className="media-body ">
                                                                    <small className="float-right">2h ago</small>
                                                                    <strong>Janet Rosowski</strong> add 1 photo on <strong>Monica Smith</strong>. <br />
                                                                    <small className="text-muted">2 days ago at 8:30am</small>
                                                                </div>
                                                            </div>
                                                            <div className="feed-element">
                                                                <a href="#0" className="float-left">
                                                                    <img alt="" className="rounded-circle" src="img/a4.jpg" />
                                                                </a>

                                                                <div className="media-body ">
                                                                    <small className="float-right text-navy">5h ago</small>
                                                                    <strong>Chris Johnatan Overtunk</strong> started following <strong>Monica Smith</strong>. <br />
                                                                    <small className="text-muted">Yesterday 1:21 pm - 11.06.2014</small>
                                                                    <div className="actions">
                                                                        <a href="#0" className="btn btn-xs btn-white"><i className="fa fa-thumbs-up"></i> Like </a>
                                                                        <a href="#0" className="btn btn-xs btn-white"><i className="fa fa-heart"></i> Love</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="feed-element">
                                                                <a href="#0" className="float-left">
                                                                    <img alt="" className="rounded-circle" src="img/a5.jpg" />
                                                                </a>

                                                                <div className="media-body ">
                                                                    <small className="float-right">2h ago</small>
                                                                    <strong>Kim Smith</strong> posted message on <strong>Monica Smith</strong> site. <br />
                                                                    <small className="text-muted">Yesterday 5:20 pm - 12.06.2014</small>
                                                                    <div className="well">
                                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                                                        Over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="feed-element">
                                                                <a href="#0" className="float-left">
                                                                    <img alt="" className="rounded-circle" src="img/profile.jpg" />
                                                                </a>

                                                                <div className="media-body ">
                                                                    <small className="float-right">23h ago</small>
                                                                    <strong>Monica Smith</strong> love <strong>Kim Smith</strong>. <br />
                                                                    <small className="text-muted">2 days ago at 2:30 am - 11.06.2014</small>
                                                                </div>
                                                            </div>
                                                            <div className="feed-element">
                                                                <a href="#0" className="float-left">
                                                                    <img alt="" className="rounded-circle" src="img/a7.jpg" />
                                                                </a>

                                                                <div className="media-body ">
                                                                    <small className="float-right">46h ago</small>
                                                                    <strong>Mike Loreipsum</strong> started following <strong>Monica Smith</strong>. <br />
                                                                    <small className="text-muted">3 days ago at 7:58 pm - 10.06.2014</small>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    {isCustomerActiveTab && 1 === 2 && <OrderDetailCustomer customerData={customerData} customerContacts={customerContacts} customerAddresses={customerAddresses} getEmail={getEmail} getPhone={getPhone} />}
                                                    {(orderGoods !== undefined) && <OrderDetailGoods orderGoods={orderGoods} />}
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="wrapper wrapper-content project-manager">





                            <h4>Project description</h4>
                            <img src="img/zender_logo.png" alt="" åßclassName="img-fluid" />

                            <p className="small">
                                There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look
                                even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing
                            </p>
                            <h5>Zboží</h5>
                            {/* <ul className="list-unstyled project-files">
                                {orderGoods?.length > 0 && orderGoods.map((oneMaterial) => (
                                    <li> {oneMaterial.nazev_zbozi}   {oneMaterial.mnozstvi}ks {oneMaterial.cena}Kč  </li>
                                ))}
                            </ul> */}
                            <p className="small font-bold">
                                <span><i className="fa fa-circle text-warning"></i> High priority</span>
                            </p>
                            {/* <h5>Project tag</h5>
                            <ul className="tag-list" style={paddingZero}>
                                <li><a ref={() => false}><i className="fa fa-tag"></i> Zender</a></li>
                                <li><a ref={() => false}><i className="fa fa-tag"></i> Lorem ipsum</a></li>
                                <li><a ref={() => false}><i className="fa fa-tag"></i> Passages</a></li>
                                <li><a ref={() => false}><i className="fa fa-tag"></i> Variations</a></li>
                            </ul> */}
                            {/* <h5>Přílohy</h5>
                            <ul className="list-unstyled project-files">
                                {orderFiles?.length > 0 && orderFiles.map((oneFile) => (
                                    <li><button className="btn btn-white btn-xs">{getFileTypeLabel(oneFile, orderDetailData)}</button><a ref={() => false}><i className="fa fa-file"></i> {oneFile.nazev} ({
                                        (new Date(Date.parse(oneFile.creation_date))).toLocaleDateString('cs-CZ')})</a></li>
                                ))}
                            </ul> */}
                            <div className="text-left m-t-md">
                                <a href="#0" className="btn btn-xs btn-primary">Přidat přílohu</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default OrderDetail