import { BASE_API_URL } from '../constants';

async function getAllData(filters = {}) {
    // const url = 'https://newis-backend.kubyx.cz/barva';
    const url = `${BASE_API_URL}/v2/api/objednavka`;

    const data = await fetch(url, {
        method: "POST",
        body: JSON.stringify(filters),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-access-token": sessionStorage.getItem("accessToken"),
        },
    });
    const response = await data.json();
    return response;
}

async function getOne(orderId) {
    // const url = 'https://newis-backend.kubyx.cz/barva';
    const url = `${BASE_API_URL}/v2/api/objednavka/${orderId}`;

    const data = await fetch(url, {
        // method: "POST",
        // body: JSON.stringify(filters),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-access-token": sessionStorage.getItem("accessToken"),
        },
    });
    const response = await data.json();
    return response;
}

const ordersService = {
    getAllData,
    getOne
};

export default ordersService;
