import { BASE_API_URL } from '../constants';

async function getAll() {
    const url = `${BASE_API_URL}/barva`;
    const data = await fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
    const response = await data.json();
    return response
}
 
export default {
    getAll
}