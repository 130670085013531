import { Fragment } from "react";
import FiremniCenyBarvaButton from "./FiremniCenyBarvaButton";

const FiremniCenyBarva = (props) => {

    const barva = props.barva
    const textilId = props.textilId

    const getColorStyle = (rgb) => {
        return {
            "zIndex": "1",
            width: "23px",
            height: "23px",
            "borderColor": "black",
            border: "solid 1px",
            "backgroundColor": "rgb(" + rgb + ")",
            visibility: "visible",
        };
    };

    const getPrice = (barvaId, velikostId) => {
        let retCena = props.cena;
        const cena = props.detail.filter(
            (detail) =>
                detail.id_velikost === velikostId && detail.id_barva === barvaId
        );

        if (cena.length > 0) retCena = cena[0].cena;

        return retCena;
    };

    const isExcluded = (barvaId, velikostId) => {
        let isExcluded = false;
        const data = props.detail.filter(
            (detail) =>
                Number(detail.id_velikost) === Number(velikostId) && Number(detail.id_barva) === Number(barvaId)
        );
        if (data.length > 0) isExcluded = data[0].exclude_web;
        return isExcluded;
    };



    return (
        <Fragment>
            <tr>
                <td>
                    <button
                        id="colorRect"
                        style={getColorStyle(barva.rgb)}
                        className="btn btn-default btn-xs"
                    />
                    &nbsp;
                    {barva.popis}
                </td>
                {props.velikosti.map((oneVelikost) => {
                    const price = getPrice(barva.id, oneVelikost.velikost.id)
                    return (
                        <Fragment key={barva.id + oneVelikost.velikost.id}>
                            <td>
                                <FiremniCenyBarvaButton barvaId={barva.id}
                                    velikostId={oneVelikost.velikost.id}
                                    textilId={textilId}
                                    detail={props.detail}
                                    cena={props.cena}
                                    isExcluded={isExcluded(barva.id, oneVelikost.velikost.id)}
                                    price={price} />
                            </td>
                        </Fragment>
                    );
                })}
            </tr>
        </Fragment>
    );
};

export default FiremniCenyBarva;
