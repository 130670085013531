const Menu = (props) => {
    const collapsedMenuStyle = {};

    return (
        <ul className="nav metismenu" id="side-menu">
            <li className="nav-header">
                <div className="dropdown profile-element">
                    {/* <a data-toggle="dropdown" className="dropdown-toggle" href="#0">
                        <span className="block m-t-xs font-bold">Example user</span>
                        <span className="text-muted text-xs block">
                            menu <b className="caret"></b>
                        </span>
                    </a> */}
                    <ul className="dropdown-menu animated fadeInRight m-t-xs">
                        <li>
                            <a className="dropdown-item" href="login.html">
                                Logout
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="logo-element">IN+</div>
            </li>
            <li className="active">
                <a href="index.html" aria-expanded="true">
                    <i className="fa fa-th-large"></i> <span className="nav-label">Textil</span>{' '}
                    <span className="fa arrow"></span>
                </a>
                <ul className="nav nav-second-level collapse in" aria-expanded="true" style={collapsedMenuStyle}>
                <li>
                        <a href="/textil">Textil</a>
                    </li>
                    <li>
                        <a href="/color-list">Barva textilu</a>
                    </li>
                    <li>
                        <a href="/sizes-list">Velikosti</a>
                    </li>
                    <li>
                        <a href="/printcolor-list">Barva potisku</a>
                    </li>
                    <li>
                        <a href="/textil-type-list">Typ textilu</a>
                    </li>
                    <li>
                        <a href="/packing-type-list">Typ baleni</a>
                    </li>
                    <li>
                        <a href="/supplier-list">Dodavatel</a>
                        
                    </li>
                </ul>
            </li>

            {/* <li>
                <a href="minor.html">
                    <i className="fa fa-th-large"></i> <span className="nav-label">Minor view</span>{' '}
                </a>
            </li> */}
        </ul>
    );
};

export default Menu;
