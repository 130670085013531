import { BASE_API_URL } from '../constants';

async function getTextilPrictures(textilId) {
    const url = `${BASE_API_URL}/textil-obrazek/${textilId}`;
    const data = await fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    })
    const response = await data.json();
    return response
}

async function setPictureFirst(textilId, id) {
    const url = `${BASE_API_URL}/textil-obrazek/setFirst/${textilId}/${id}`;
    const data = await fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'x-access-token': sessionStorage.getItem("accessToken")
        }
    })
    const response = await data.json();
    return response
}

async function savePicture(textilId, colorId, file) {
    // console.log(file)
    const submittedData = { "textilId": textilId, "colorId": colorId, "picture": file }
    const url = `${BASE_API_URL}/textil-obrazek`;
    const data = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(submittedData),
        headers: {
            'Content-Type': 'application/json',
            'Accept': "application/json",
            'x-access-token': sessionStorage.getItem("accessToken")
        }
    });
    const response = await data.json();
    return response

}

async function deletePicture(id) {
    const url = `${BASE_API_URL}/textil-obrazek/${id}`;
    const data = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'x-access-token': sessionStorage.getItem("accessToken")
        }
    })
    const response = await data.json();
    return response
}

export default {
    getTextilPrictures,
    setPictureFirst,
    savePicture,
    deletePicture
}