import { BASE_API_URL } from '../constants';

async function getAllData(pageNo, perPageNo, sortBy, sortDir) {
  return await getAllColors(pageNo, perPageNo, sortBy, sortDir);
}

async function saveData(colorData) {
  return await saveColor(colorData);
}

async function deleteData(colorData) {
  return await deleteColor(colorData);
}

async function getAllColors(pageNo, perPageNo, sortBy, sortDir) {
  const url = `${BASE_API_URL}/barva/${pageNo}/${perPageNo}/${sortBy}/${sortDir}`;
  const data = fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (myJson) {
      return myJson;
    });
  console.log(data);
  return data;
}

async function saveColor(colorData) {
  const url = `${BASE_API_URL}/barva`;
  console.log("colorData");
  console.log(colorData);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(colorData),
  };

  const data = fetch(url, requestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(function (myJson) {
      return myJson;
    });
  console.log(data);
  return data;
}

async function deleteColor(colorData) {
  const url = `${BASE_API_URL}/barva`;
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(colorData),
  };
  const data = fetch(url, requestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(function (myJson) {
      return myJson;
    });
  console.log(data);
  return data;
}

export default {
  getAllColors,
  saveColor,
  deleteColor,
  getAllData,
  saveData,
  deleteData,
};
