import React from "react";
import Moment from "moment";

const TextilListItem = (props) => {
 
    const editTextil = (event) => {
        const id = event.target.id.split("#")[1];
        event.preventDefault();
        window.location.href = "/textil/" + id;
    };

    return (
        <React.Fragment>
            <tr className="footable-even">
                <td className="footable-visible footable-first-column">
                    <span className="footable-toggle"></span>
                    {props.textil.kod}
                </td>
                <td className="footable-visible">{props.textil.nazev}</td>
                <td className="footable-visible">
                    {props.textil.textil_dodavatels[0]?.dodavatel?.nazev}
                </td>
                <td className="footable-visible">
                    {new Intl.NumberFormat("cs-CS", {
                        style: "currency",
                        currency: "CZK",
                        minimumFractionDigits: 0,
                    }).format(props.textil.prodejni_cena)}
                </td>
                <td className="footable-visible">
                    {Moment(props.textil.last_updated).format(
                        "DD.MM.YYYY HH:mm"
                    )}
                </td>
                <td className="text-right footable-visible footable-last-column">
                    <div className="btn-group">
                        <button
                            className="btn-white btn btn-xs"
                            id={`edit-textil-button#${props.textil.id}`}
                            onClick={editTextil}
                        >
                            Editovat
                        </button>
                        <button className="btn-white btn btn-xs">Smazat</button>
                    </div>
                </td>
            </tr>
        </React.Fragment>
    );
};

export default TextilListItem;
