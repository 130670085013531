
import chroma from 'chroma-js';

const nvl = (inData) => {
    let retData = (inData !== null ? inData : undefined)
    return (retData !== undefined ? retData : ``)
}

const nvlBrackets = (inData) => {
    let useBrackets = (inData !== undefined ? true : false)
    if (inData === null)
        useBrackets = false
    if (useBrackets)
        return `(${inData})`
    return ``
}

const rgbToHex = (rgb) => {
    let hex = Number(rgb).toString(16);

    if (hex.length < 2) {
        hex = "0" + hex;
    }
    return hex === "NaN" ? "00" : hex;
};

const fullColorHex = (color) => {
    let rgb = color
    if (color === undefined)
        rgb = '255,255,255'.split(',')

    var red = rgbToHex(rgb[0]);
    var green = rgbToHex(rgb[1]);
    var blue = rgbToHex(rgb[2]);
    return red + green + blue;
};

const getColourStyles = () => {


    return {
        control: (styles) => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const hexColor = "#" + fullColorHex(data.color)
            const color = chroma(hexColor);
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                        ? data.color
                        : isFocused
                            ? color.alpha(0.3).css()
                            : undefined,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? chroma.contrast(color, 'white') > 2
                            ? 'white'
                            : 'black'
                        : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? data.color
                            : color.alpha(0.3).css()
                        : undefined,
                },
            };
        },
        multiValue: (styles, { data }) => {
            const hexColor = "#" + fullColorHex(data.color)
            const color = chroma(hexColor);
            return {
                ...styles,
                backgroundColor: color.alpha(.5).css(),
            };
        },
        multiValueLabel: (styles) => {

            return {
                ...styles,
                color: "#000000",
            }
        },
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: data.color,
            ':hover': {
                backgroundColor: data.color,
                color: 'white',
            },
        }),
    };

}

const nvlSep = (inData) => {
    let retData = (inData !== null ? inData : undefined)
    retData = (retData !== undefined ? `, ${retData}` : ``)
    retData = (retData.trim().length === 1) ? `` : retData
    return retData
}



export default {
    getColourStyles,
    nvl,
    nvlBrackets,
    nvlSep
}