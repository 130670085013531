import { useDropzone } from 'react-dropzone'
import textilObrazekService from '../services/textil-obrazek.service'
const { Fragment, useCallback } = require("react")

const MissingColor = (props) => {

    const picture = props.picture
    const setChange = props.setChange
    const change = props.change


    function arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files

        acceptedFiles.forEach((file) => {
            let baseURL = "";
            const reader = new FileReader()
            reader.readAsDataURL(file);
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                // const binaryStr = reader.result
                // console.log(binaryStr.toString('base64'))
                baseURL = reader.result;
                // console.log(baseURL);
                // resolve(baseURL);
                textilObrazekService.savePicture(props.textilId, props.picture.id, baseURL)
                console.log(setChange(!change))
            }


        })

    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: { 'image/*': [] } })

    return (
        <Fragment>
            <div className="col-md-3">
                <div className="ibox">
                    <div className="ibox-content product-box">
                        <div className="product-imitation"><button className="btn btn-danger btn-circle btn-lg" type="button"><i className="fa fa-times"></i>
                        </button>
                        </div>
                        <div className="product-desc">
                            <span className="product-price">
                                {`${picture.popis}`}
                            </span>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {
                                    isDragActive ?
                                        <p>Přetáhněte sem obrázek ...</p> :
                                        <p>Přetáhněte sem obrázek nebo klikněte pro výběr obrázku</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default MissingColor
