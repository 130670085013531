import React, { Fragment, useEffect, useState, useCallback } from 'react';
import ordersService from '../../services/orders.service';
import OrderListItem from './OrderListItem';

const displayNoneStyle = { display: "none" }
const OrderList = (props) => {
    const [ordersData, setOrdersData] = useState([]);
    const [ordersDataCnt, setOrdersDataCnt] = useState([]);


    const fetchData = useCallback(async (sortByAttr, sortDirAttr) => {
        let sort = 'ASC'
        switch (sortDirAttr) {
            case true:
                sort = 'ASC'
                break;
            default:
                sort = 'DESC'
                break;
        }
        let order = {
            sortByAttr: sortByAttr,
            sortDirAttr: sortDirAttr
        }
        const opts = {
            order,
            limit: 200
        }
        const itemsData = await ordersService.getAllData(opts)
        setOrdersData(itemsData.data)
        setOrdersDataCnt(itemsData.cnt)
    }, []);

    useEffect(() => {

        fetchData('id_objednavky', 'DESC')

    }, []);

    return (
        <Fragment>
            <div className="wrapper wrapper-content  animated fadeInRight">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox">
                            <div className="ibox-title">
                                <h5>Issue list</h5>
                                <div className="ibox-tools">
                                    <button className="btn btn-primary btn-xs">Add new issue</button>
                                </div>
                            </div>
                            <div className="ibox-content">

                                <div className="m-b-lg">

                                    <div className="input-group input-group-sm">
                                        <input type="text" className="form-control" placeholder="Search issue by name..." />
                                        <div className="input-group-append">
                                            <button className="btn btn-white" type="button">Search</button>
                                        </div>
                                    </div>

                                    <div className="m-t-md">

                                        <div className="float-right">
                                            <button type="button" className="btn btn-sm btn-white"> <i className="fa fa-comments"></i> </button>
                                            <button type="button" className="btn btn-sm btn-white"> <i className="fa fa-user"></i> </button>
                                            <button type="button" className="btn btn-sm btn-white"> <i className="fa fa-list"></i> </button>
                                            <button type="button" className="btn btn-sm btn-white"> <i className="fa fa-pencil"></i> </button>
                                            <button type="button" className="btn btn-sm btn-white"> <i className="fa fa-print"></i> </button>
                                            <button type="button" className="btn btn-sm btn-white"> <i className="fa fa-cogs"></i> </button>
                                        </div>

                                        <strong>Nalezeno {ordersDataCnt} objednávek.</strong>



                                    </div>

                                </div>

                                <div className="table-responsive">
                                    <table className="table table-hover issue-tracker">
                                        <tbody>
                                            {ordersData?.length > 0 && ordersData.map((oneOrder) => (
                                                <OrderListItem orderData={oneOrder} key={`orderLine#${oneOrder.id_objednavky}`} />
                                            ))}

                                            <tr>
                                                <td>
                                                    <span className="label label-primary">Added</span>
                                                </td>
                                                <td className="issue-info">
                                                    <a href={() => false}>
                                                        ISSUE-17
                                                    </a>

                                                    <small>
                                                        Desktop publishing packages and web page editors now use Lorem Ipsum as their default model text
                                                    </small>
                                                </td>
                                                <td>
                                                    Anna Smith
                                                </td>
                                                <td>
                                                    10.02.2015 05:32 am
                                                </td>
                                                <td>
                                                    <span className="pie" style={displayNoneStyle}>3,2</span><svg className="peity" height="16" width="16"><path d="M 8 8 L 8 0 A 8 8 0 1 1 3.297717981660216 14.47213595499958 Z" fill="#1ab394"></path><path d="M 8 8 L 3.297717981660216 14.47213595499958 A 8 8 0 0 1 7.999999999999998 0 Z" fill="#d7d7d7"></path></svg>
                                                    2d
                                                </td>
                                                <td className="text-right">
                                                    <button className="btn btn-white btn-xs"> Tag</button>
                                                    <button className="btn btn-white btn-xs"> Rag</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="label label-primary">Added</span>
                                                </td>
                                                <td className="issue-info">
                                                    <a href={() => false}>
                                                        ISSUE-12
                                                    </a>

                                                    <small>
                                                        It is a long established fact that a reader will be
                                                    </small>
                                                </td>
                                                <td>
                                                    Anthony Jackson
                                                </td>
                                                <td>
                                                    02.03.2015 06:02 am
                                                </td>
                                                <td>
                                                    <span className="pie" style={displayNoneStyle}>1,2</span><svg className="peity" height="16" width="16"><path d="M 8 8 L 8 0 A 8 8 0 0 1 14.92820323027551 11.999999999999998 Z" fill="#1ab394"></path><path d="M 8 8 L 14.92820323027551 11.999999999999998 A 8 8 0 1 1 7.999999999999998 0 Z" fill="#d7d7d7"></path></svg>
                                                    1d
                                                </td>
                                                <td className="text-right">
                                                    <button className="btn btn-white btn-xs"> Tag</button>
                                                    <button className="btn btn-white btn-xs"> Mag</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="label label-primary">Added</span>
                                                </td>
                                                <td className="issue-info">
                                                    <a href={() => false}>
                                                        ISSUE-11
                                                    </a>

                                                    <small>
                                                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                                                    </small>
                                                </td>
                                                <td>
                                                    Monica Proven
                                                </td>
                                                <td>
                                                    01.10.2015 11:02 pm
                                                </td>
                                                <td>
                                                    <span className="pie" style={displayNoneStyle}>4,2</span><svg className="peity" height="16" width="16"><path d="M 8 8 L 8 0 A 8 8 0 1 1 1.0717967697244921 12.000000000000004 Z" fill="#1ab394"></path><path d="M 8 8 L 1.0717967697244921 12.000000000000004 A 8 8 0 0 1 7.999999999999998 0 Z" fill="#d7d7d7"></path></svg>
                                                    3d
                                                </td>
                                                <td className="text-right">
                                                    <button className="btn btn-white btn-xs"> Tag</button>
                                                    <button className="btn btn-white btn-xs"> Rag</button>
                                                    <button className="btn btn-white btn-xs"> Dag</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="label label-warning">Fixed</span>
                                                </td>
                                                <td className="issue-info">
                                                    <a href={() => false}>
                                                        ISSUE-07
                                                    </a>

                                                    <small>
                                                        Always free from repetition, injected humour, or non-characteristic words etc.
                                                    </small>
                                                </td>
                                                <td>
                                                    Alex Ferguson
                                                </td>
                                                <td>
                                                    28.11.2015 05:10 pm
                                                </td>
                                                <td>
                                                    <span className="pie" style={displayNoneStyle}>1,2</span><svg className="peity" height="16" width="16"><path d="M 8 8 L 8 0 A 8 8 0 0 1 14.92820323027551 11.999999999999998 Z" fill="#1ab394"></path><path d="M 8 8 L 14.92820323027551 11.999999999999998 A 8 8 0 1 1 7.999999999999998 0 Z" fill="#d7d7d7"></path></svg>
                                                    2d
                                                </td>
                                                <td className="text-right">
                                                    <button className="btn btn-white btn-xs"> Tag</button>
                                                    <button className="btn btn-white btn-xs"> Dag</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="label label-warning">Fixed</span>
                                                </td>
                                                <td className="issue-info">
                                                    <a href={() => false}>
                                                        ISSUE-07
                                                    </a>

                                                    <small>
                                                        Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit am
                                                    </small>
                                                </td>
                                                <td>
                                                    Mark Conor
                                                </td>
                                                <td>
                                                    18.09.2015 06:20 pm
                                                </td>
                                                <td>
                                                    <span className="pie" style={displayNoneStyle}>3,2</span><svg className="peity" height="16" width="16"><path d="M 8 8 L 8 0 A 8 8 0 1 1 3.297717981660216 14.47213595499958 Z" fill="#1ab394"></path><path d="M 8 8 L 3.297717981660216 14.47213595499958 A 8 8 0 0 1 7.999999999999998 0 Z" fill="#d7d7d7"></path></svg>
                                                    3d
                                                </td>
                                                <td className="text-right">
                                                    <button className="btn btn-white btn-xs"> Tag</button>
                                                    <button className="btn btn-white btn-xs"> Mag</button>
                                                    <button className="btn btn-white btn-xs"> Dag</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="label label-warning">Fixed</span>
                                                </td>
                                                <td className="issue-info">
                                                    <a href={() => false}>
                                                        ISSUE-06
                                                    </a>

                                                    <small>
                                                        Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit am
                                                    </small>
                                                </td>
                                                <td>
                                                    Carol Jackson
                                                </td>
                                                <td>
                                                    11.03.2015 07:30 pm
                                                </td>
                                                <td>
                                                    <span className="pie" style={displayNoneStyle}>3,2</span><svg className="peity" height="16" width="16"><path d="M 8 8 L 8 0 A 8 8 0 1 1 3.297717981660216 14.47213595499958 Z" fill="#1ab394"></path><path d="M 8 8 L 3.297717981660216 14.47213595499958 A 8 8 0 0 1 7.999999999999998 0 Z" fill="#d7d7d7"></path></svg>
                                                    2d
                                                </td>
                                                <td className="text-right">
                                                    <button className="btn btn-white btn-xs"> Mag</button>
                                                    <button className="btn btn-white btn-xs"> Dag</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="label label-warning">Fixed</span>
                                                </td>
                                                <td className="issue-info">
                                                    <a href={() => false}>
                                                        ISSUE-05
                                                    </a>

                                                    <small>
                                                        Content here, content here', making it look like readable English. Many desktop
                                                    </small>
                                                </td>
                                                <td>
                                                    Carol Jackson
                                                </td>
                                                <td>
                                                    05.04.2015 08:40 pm
                                                </td>
                                                <td>
                                                    <span className="pie" style={displayNoneStyle}>3,2</span><svg className="peity" height="16" width="16"><path d="M 8 8 L 8 0 A 8 8 0 1 1 3.297717981660216 14.47213595499958 Z" fill="#1ab394"></path><path d="M 8 8 L 3.297717981660216 14.47213595499958 A 8 8 0 0 1 7.999999999999998 0 Z" fill="#d7d7d7"></path></svg>
                                                    2d
                                                </td>
                                                <td className="text-right">
                                                    <button className="btn btn-white btn-xs"> Mag</button>
                                                    <button className="btn btn-white btn-xs"> Dag</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="label label-warning">Fixed</span>
                                                </td>
                                                <td className="issue-info">
                                                    <a href={() => false}>
                                                        ISSUE-04
                                                    </a>

                                                    <small>
                                                        Virginia, looked up one of the more obscure Latin words, consectetur
                                                    </small>
                                                </td>
                                                <td>
                                                    Monica Smith
                                                </td>
                                                <td>
                                                    10.06.2014 08:10 pm
                                                </td>
                                                <td>
                                                    <span className="pie" style={displayNoneStyle}>5,7</span><svg className="peity" height="16" width="16"><path d="M 8 8 L 8 0 A 8 8 0 0 1 12 14.92820323027551 Z" fill="#1ab394"></path><path d="M 8 8 L 12 14.92820323027551 A 8 8 0 1 1 7.999999999999998 0 Z" fill="#d7d7d7"></path></svg>
                                                    4d
                                                </td>
                                                <td className="text-right">
                                                    <button className="btn btn-white btn-xs"> Mag</button>
                                                    <button className="btn btn-white btn-xs"> Cag</button>
                                                    <button className="btn btn-white btn-xs"> Dag</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="label label-warning">Fixed</span>
                                                </td>
                                                <td className="issue-info">
                                                    <a href={() => false}>
                                                        ISSUE-03
                                                    </a>

                                                    <small>
                                                        Injected humour, or randomised words which don't l
                                                    </small>
                                                </td>
                                                <td>
                                                    Anna Johnson
                                                </td>
                                                <td>
                                                    13.05.2014 09:20 pm
                                                </td>
                                                <td>
                                                    <span className="pie" style={displayNoneStyle}>2,7</span><svg className="peity" height="16" width="16"><path d="M 8 8 L 8 0 A 8 8 0 0 1 15.878462024097665 6.610814578664558 Z" fill="#1ab394"></path><path d="M 8 8 L 15.878462024097665 6.610814578664558 A 8 8 0 1 1 7.999999999999998 0 Z" fill="#d7d7d7"></path></svg>
                                                    3d
                                                </td>
                                                <td className="text-right">
                                                    <button className="btn btn-white btn-xs"> Cag</button>
                                                    <button className="btn btn-white btn-xs"> Dag</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="label label-danger">Bug</span>
                                                </td>
                                                <td className="issue-info">
                                                    <a href={() => false}>
                                                        ISSUE-44
                                                    </a>

                                                    <small>
                                                        This is issue with the coresponding note
                                                    </small>
                                                </td>
                                                <td>
                                                    Adrian Novak
                                                </td>
                                                <td>
                                                    12.02.2015 10:00 am
                                                </td>
                                                <td>
                                                    <span className="pie" style={displayNoneStyle}>0.52,1.041</span><svg className="peity" height="16" width="16"><path d="M 8 8 L 8 0 A 8 8 0 0 1 14.933563796318165 11.990700825968545 Z" fill="#1ab394"></path><path d="M 8 8 L 14.933563796318165 11.990700825968545 A 8 8 0 1 1 7.999999999999998 0 Z" fill="#d7d7d7"></path></svg>
                                                    2d
                                                </td>
                                                <td className="text-right">
                                                    <button className="btn btn-white btn-xs"> Tag</button>
                                                    <button className="btn btn-white btn-xs"> Mag</button>
                                                    <button className="btn btn-white btn-xs"> Rag</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="label label-danger">Bug</span>
                                                </td>
                                                <td className="issue-info">
                                                    <a href={() => false}>
                                                        ISSUE-41
                                                    </a>

                                                    <small>
                                                        Desktop publishing packages and web page editors now use Lorem Ipsum as their default model text
                                                    </small>
                                                </td>
                                                <td>
                                                    Anna Smith
                                                </td>
                                                <td>
                                                    10.02.2015 05:32 am
                                                </td>
                                                <td>
                                                    <span className="pie" style={displayNoneStyle}>3,2</span><svg className="peity" height="16" width="16"><path d="M 8 8 L 8 0 A 8 8 0 1 1 3.297717981660216 14.47213595499958 Z" fill="#1ab394"></path><path d="M 8 8 L 3.297717981660216 14.47213595499958 A 8 8 0 0 1 7.999999999999998 0 Z" fill="#d7d7d7"></path></svg>
                                                    2d
                                                </td>
                                                <td className="text-right">
                                                    <button className="btn btn-white btn-xs"> Tag</button>
                                                    <button className="btn btn-white btn-xs"> Rag</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="label label-danger">Bug</span>
                                                </td>
                                                <td className="issue-info">
                                                    <a href={() => false}>
                                                        ISSUE-34
                                                    </a>

                                                    <small>
                                                        It is a long established fact that a reader will be
                                                    </small>
                                                </td>
                                                <td>
                                                    Anthony Jackson
                                                </td>
                                                <td>
                                                    02.03.2015 06:02 am
                                                </td>
                                                <td>
                                                    <span className="pie" style={displayNoneStyle}>1,2</span><svg className="peity" height="16" width="16"><path d="M 8 8 L 8 0 A 8 8 0 0 1 14.92820323027551 11.999999999999998 Z" fill="#1ab394"></path><path d="M 8 8 L 14.92820323027551 11.999999999999998 A 8 8 0 1 1 7.999999999999998 0 Z" fill="#d7d7d7"></path></svg>
                                                    1d
                                                </td>
                                                <td className="text-right">
                                                    <button className="btn btn-white btn-xs"> Tag</button>
                                                    <button className="btn btn-white btn-xs"> Mag</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="label label-danger">Bug</span>
                                                </td>
                                                <td className="issue-info">
                                                    <a href={() => false}>
                                                        ISSUE-46
                                                    </a>

                                                    <small>
                                                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                                                    </small>
                                                </td>
                                                <td>
                                                    Monica Proven
                                                </td>
                                                <td>
                                                    01.10.2015 11:02 pm
                                                </td>
                                                <td>
                                                    <span className="pie" style={displayNoneStyle}>4,2</span><svg className="peity" height="16" width="16"><path d="M 8 8 L 8 0 A 8 8 0 1 1 1.0717967697244921 12.000000000000004 Z" fill="#1ab394"></path><path d="M 8 8 L 1.0717967697244921 12.000000000000004 A 8 8 0 0 1 7.999999999999998 0 Z" fill="#d7d7d7"></path></svg>
                                                    3d
                                                </td>
                                                <td className="text-right">
                                                    <button className="btn btn-white btn-xs"> Tag</button>
                                                    <button className="btn btn-white btn-xs"> Rag</button>
                                                    <button className="btn btn-white btn-xs"> Dag</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="label label-danger">Bug</span>
                                                </td>
                                                <td className="issue-info">
                                                    <a href={() => false}>
                                                        ISSUE-51
                                                    </a>

                                                    <small>
                                                        Always free from repetition, injected humour, or non-characteristic words etc.
                                                    </small>
                                                </td>
                                                <td>
                                                    Alex Ferguson
                                                </td>
                                                <td>
                                                    28.11.2015 05:10 pm
                                                </td>
                                                <td>
                                                    <span className="pie" style={displayNoneStyle}>1,2</span><svg className="peity" height="16" width="16"><path d="M 8 8 L 8 0 A 8 8 0 0 1 14.92820323027551 11.999999999999998 Z" fill="#1ab394"></path><path d="M 8 8 L 14.92820323027551 11.999999999999998 A 8 8 0 1 1 7.999999999999998 0 Z" fill="#d7d7d7"></path></svg>
                                                    2d
                                                </td>
                                                <td className="text-right">
                                                    <button className="btn btn-white btn-xs"> Tag</button>
                                                    <button className="btn btn-white btn-xs"> Dag</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="label label-danger">Bug</span>
                                                </td>
                                                <td className="issue-info">
                                                    <a href={() => false}>
                                                        ISSUE-52
                                                    </a>

                                                    <small>
                                                        Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit am
                                                    </small>
                                                </td>
                                                <td>
                                                    Mark Conor
                                                </td>
                                                <td>
                                                    18.09.2015 06:20 pm
                                                </td>
                                                <td>
                                                    <span className="pie" style={displayNoneStyle}>3,2</span><svg className="peity" height="16" width="16"><path d="M 8 8 L 8 0 A 8 8 0 1 1 3.297717981660216 14.47213595499958 Z" fill="#1ab394"></path><path d="M 8 8 L 3.297717981660216 14.47213595499958 A 8 8 0 0 1 7.999999999999998 0 Z" fill="#d7d7d7"></path></svg>
                                                    3d
                                                </td>
                                                <td className="text-right">
                                                    <button className="btn btn-white btn-xs"> Tag</button>
                                                    <button className="btn btn-white btn-xs"> Mag</button>
                                                    <button className="btn btn-white btn-xs"> Dag</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </div>
        </Fragment>
    )
}

export default OrderList