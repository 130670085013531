import { BASE_API_URL } from '../constants';

async function getAllData(pageNo, perPageNo, sortBy, sortDir) {
    // const url = 'https://newis-backend.kubyx.cz/barva';
    const url = `${BASE_API_URL}/typ-textilu/${pageNo}/${perPageNo}/${sortBy}/${sortDir}`;
    const data = fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
        .then(function (response) {
            return response.json();
        })
        .then(function (myJson) {
            return myJson;
        });
    console.log(data);
    return data;
}


async function saveData(data) {
    const url = `${BASE_API_URL}/typ-textilu`;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify(data),
    };

    const resData = fetch(url, requestOptions)
        .then(function (response) {
            return response.json();
        })
        .then(function (myJson) {
            return myJson;
        });
    console.log(resData);
    return resData;
}

async function deleteData(data) {
    const url = `${BASE_API_URL}/typ-textilu`;
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify(data),
    };
    const resData = fetch(url, requestOptions)
        .then(function (response) {
            return response.json();
        })
        .then(function (myJson) {
            return myJson;
        });
    console.log(resData);
    return resData;
}

async function getAll() {
    const url = `${BASE_API_URL}/typ-textilu`;
    const data = await fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
    const response = await data.json();
    return response
}

async function getAllLight() {
    const url = `${BASE_API_URL}/typ-textilu/light`;
    const data = await fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
    const response = await data.json();
    return response
}
export default {
    getAll,
    getAllData,
    saveData,
    deleteData,
    getAllLight
};
