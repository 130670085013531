import React, { Fragment, useEffect, useState } from 'react';
import RgbRect from './RgbRect';

const ItemModal = (props) => {
    const data = props.data;
    const metaData = props.metaData;
    const setIsOpen = props.setIsOpen
    const itemService = props.itemService
    const setItemSaved = props.setItemSaved
    const itemSaved = props.itemSaved
    const [itemId, setItemId] = useState();
    const [dataArray, setDataArray] = useState({ id: data?.id })
    // const [colorRgb, setColorRgb] = useState()


    const closeModal = () => {
        document.body.classList.remove('modal-open');
        document.getElementById('modal-backdrop').classList.remove('modal-backdrop');
        document.getElementById('modal-backdrop').classList.remove('fade');
        document.getElementById('modal-backdrop').classList.remove('show');
        setIsOpen(false)
        clearFormData();
    };

    const openModal = () => {
        document.body.classList.add('modal-open');
        document.getElementById('modal-backdrop').classList.add('modal-backdrop');
        document.getElementById('modal-backdrop').classList.add('fade');
        document.getElementById('modal-backdrop').classList.add('show');
    };

    async function onSaveItem(event) {
        const saveResult = await itemService.saveData(dataArray)
        if (saveResult?.status === 'OK') {
            clearFormData();
            closeModal();
            setItemSaved(!itemSaved);
        }
    };

    const onItemChange = (name) => (event) => {
        const changedData = { ...dataArray, [name]: event.target.value }
        setDataArray(changedData)
    };

    const clearFormData = () => {
        setDataArray({})
    }

    const isReadOnly = (isReadOnly) => {
        var retValue = false
        if (itemId !== undefined && isReadOnly)
            retValue = true
        return retValue
    }

    const getItemValue = (name) => {
        return dataArray[name]
    }


    // const onRgbChange = (event) => {
    //     const isValidRgb =
    //         event.target.value.match(
    //             new RegExp(
    //                 '^(0|255|25[0-4]|2[0-4]d|1dd|0?d?d),(0|255|25[0-4]|2[0-4]d|1dd|0?d?d),(0|255|25[0-4]|2[0-4]d|1dd|0?d?d)$'
    //             )
    //         ) != null;
    //     setColorRgb(event.target.value);
    // };

    useEffect(() => {
        setItemId(props.data.id)
        var i = 0;
        for (i = 0; i < metaData.length; i++) {
            dataArray[metaData[i].dataName] = data[metaData[i].dataName];
        }
        setDataArray(dataArray)
        openModal()
    }, [props.data.id, data, dataArray, metaData]);


    return (
        <Fragment>

            <div
                id="modal-form"
                className="modal fade show bd-example-modal-md"
                style={{ display: 'block' }}
                aria-modal="true"
            >
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h3 className="m-t-none">Nová barva</h3>
                                    {metaData?.map((itemMeta) => (
                                        <Fragment key={`modalmeta#${itemMeta.name}`}>
                                            {itemMeta.dataType !== 'rgb' && (
                                                <div className="form-group row">
                                                    <label className="col-sm-2 col-form-label">{itemMeta.label}</label>
                                                    <div className={`col-md-${itemMeta.rowsize} has-warning`}>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={getItemValue(itemMeta.name)}
                                                            onChange={onItemChange(itemMeta.name)}
                                                            id={itemMeta.name}
                                                            name={itemMeta.name}
                                                            readOnly={isReadOnly(itemMeta.isReadOnly)}
                                                        />
                                                    </div>
                                                    {itemMeta.name === 'rgb' && (
                                                        <div className="justify-content-center align-items-center">
                                                            <RgbRect rgb={props?.data['rgb']} size='35' />
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </Fragment>
                                    ))}

                                    <div className="row">
                                        <div className="col-sm"></div>
                                        <div className="col-sm"></div>
                                        <div className="col-sm-4 col-sm-offset-2">
                                            <button className="btn btn-white btn-sm" type="submit" onClick={closeModal}>
                                                Zrušit
                                            </button>
                                            &nbsp;
                                            <button
                                                className="btn btn-primary btn-sm"
                                                type="submit"
                                                onClick={onSaveItem}
                                            >
                                                Uložit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ItemModal;
