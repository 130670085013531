import React, { Fragment, useEffect, useState, useCallback } from 'react';
import sizesService from '../../services/size.service'
import ItemsList from '../colors/ItemsList';

const SizesList = (props) => {
    const listMetaData = {
        newLabel: 'Nová velikost',
        headerLabel: 'Velikost'
    }

    const metaData = [
        {
            label: 'Kód',
            name: 'kod',
            dataName: 'kod',
            rowsize: 3,
            isReadOnly: true
        },
        {
            label: 'Název',
            name: 'nazev',
            dataName: 'nazev',
            rowsize: 8

        }
    ];

    return (
        <Fragment>
            <ItemsList itemService={sizesService} metaData={metaData} listMetaData={listMetaData} />
        </Fragment>
    )
}

export default SizesList