import React, { Fragment } from 'react';
import Swal from 'sweetalert2';
// import withReactContent from 'sweetalert2-react-content';
import RgbRect from './RgbRect';
const ItemTableRow = (props) => {
    const itemData = props.itemData
    const metaData = props.metaData
    const setIsOpen = props.setIsOpen
    const setRowData = props.setRowData
    const itemService = props.itemService
    const setItemSaved = props.setItemSaved
    const itemSaved = props.itemSaved
    const pageNo = props.pageNo
    const setPageNo = props.setPageNo

    const editItem = () => {
        setIsOpen(true)
        setRowData(itemData)
    };

    // const MySwal = withReactContent(Swal);

    async function deleteItem(item) {
        Swal.fire({
            title: 'Opravdu chcete smazat záznam?',
            text: item.popis,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Zrušit',
            confirmButtonText: 'Ano, smazat!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const deleteResult = await itemService.deleteData(item);
                if (deleteResult?.original?.errno === 1451) {
                    Swal.fire('Chyba!', 'Záznam použit u textilu!', 'error');
                } else {
                    setItemSaved(!itemSaved);
                    setPageNo(pageNo)
                    Swal.fire('Smazáno!', 'Záznam smazán!', 'success');
                }
            }
        });
    }

    const Image = ({ data }) => <img src={`data:image/jpeg;base64,${data}`} height="38px" alt=""/>

    function toBase64(arr) {
        return btoa(
            arr.reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
    }

    const returnRowValue = (meta, data) => {
        var retValue = ""
        switch (meta.dataType) {
            case "rgb":
                retValue = <RgbRect rgb={data['rgb']} size='23' />
                break;
            case "image":
                const rawData = data[meta.name]?.data
                if (rawData !== undefined) {
                    const imageData = toBase64(rawData)
                    retValue = <Image data={imageData} />
                }
                break;
            default:
                retValue = data[meta.name]
                break;
        }
        return retValue;
    }

    return (
        <Fragment>
            <tr key={'colorLine' + itemData.id}>
                {metaData?.map((oneMetaData) => (
                    <td key={`itemRowLine#${oneMetaData.name}#${itemData.id}`}>{returnRowValue(oneMetaData, itemData)}</td>
                ))}
                <td>
                    <button
                        className="btn btn-primary btn-circle btn-outline"
                        type="button"
                        onClick={() => editItem()}
                        id={`edit#${itemData?.id}`}
                    >
                        <i className="fa fa-edit"></i>
                    </button>
                    <button
                        className="btn btn-danger btn-circle btn-outline"
                        type="button"
                        onClick={() => deleteItem(itemData)}
                        id={`delete#${itemData?.id}`}
                    >
                        <i className="fa fa-trash-o"></i>
                    </button>
                </td>
            </tr>
        </Fragment >
    )
}

export default ItemTableRow