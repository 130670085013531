import React, { Fragment } from 'react';
import ItemsList from './ItemsList';
import ColorService from '../../services/color.service';


const ColorList = (props) => {
    const listMetaData = {
        newLabel: 'Nová barva textilu',
        headerLabel: 'Barva textilu'
    }

    const metaData = [
        {
            label: 'Kód',
            name: 'kod',
            dataName: 'kod',
            rowsize: 3,
            isReadOnly: true
        },
        {
            label: 'Název',
            name: 'popis',
            dataName: 'popis',
            rowsize: 8

        },
        {
            label: 'Barva',
            name: 'barva',
            dataName: 'rgb',
            dataType: 'rgb',

        },
        {
            label: 'RGB',
            name: 'rgb',
            dataName: 'rgb',
            rowsize: 4
        }
    ];

    return (
        <Fragment>
            <ItemsList itemService={ColorService} metaData={metaData} listMetaData={listMetaData} />
        </Fragment>
    )

}

export default ColorList