import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import { useSelector } from "react-redux";

import TextilList from "./textil/TextilList";
import TextilDetail from "./textil/TextilDetail";
import Login from './login/Login'
import ProtectedRoute from "./middleware/ProtectedRoute";
import ColorList from './components/colors/ColorList'
import SizesList from './components/sizes/SizesList'
import PrintColorsList from './components/printColors/printColorsList'
import TypTextiluList from './components/typTextilu/typTextiluList'
import TypBaleniList from './components/lists/typBaleniList'
import SuppliersList from './components/suppliers/SuppliersList'
import OrderList from "./components/orders/OrderList";
import OrderDetail from "./components/orders/OrderDetail"


//  {auth.verifyToken(cart.token) && <App />} 
// {/* {!auth.verifyToken(cart.token) && <Login />} */}

// const auth = require("./middleware/authJwt");
//const auth = require("./middleware/authJwt");
// const cart = useSelector((state) => state.cart);



function App() {
    return (
        <Router>
            <Switch>
                <Route path="/login">
                    <Login />
                </Route>
                <ProtectedRoute exact path="/textil">
                    <TextilList />
                </ProtectedRoute>
                <ProtectedRoute exact path="/">
                    <TextilList />
                </ProtectedRoute>
                <ProtectedRoute path="/textil/:id">
                    <TextilDetail />
                </ProtectedRoute>
                <ProtectedRoute path="/color-list" exact>
                    <ColorList />
                </ProtectedRoute>
                <ProtectedRoute path="/sizes-list" exact>
                    <SizesList />
                </ProtectedRoute>
                <ProtectedRoute path="/printcolor-list" exact>
                    <PrintColorsList />
                </ProtectedRoute>
                <ProtectedRoute path="/textil-type-list" exact>
                    <TypTextiluList />
                </ProtectedRoute>
                <ProtectedRoute path="/packing-type-list" exact>
                    <TypBaleniList />
                </ProtectedRoute>
                <ProtectedRoute path="/supplier-list" exact>
                    <SuppliersList/>
                </ProtectedRoute>
                <ProtectedRoute path="/orders-detail/:id" exact>
                    <OrderDetail/>
                </ProtectedRoute>   
                <ProtectedRoute path="/orders-list" exact>
                    <OrderList/>
                </ProtectedRoute>                
            </Switch>
        </Router>
    );
}

export default App;
