import ReactDOM from 'react-dom';
import React, { Fragment } from 'react';
import Menu from './Menu';

const Layout = (props) => {
    return (
        <Fragment>
            {props.children},{ReactDOM.createPortal(<Menu />, document.getElementById('side-menu'))}
        </Fragment>
    );
};

export default Layout;
