import React, { Fragment, useEffect, useState, useCallback } from 'react';
import typTextiluService from '../../services/typ-textilu.service'
import ItemsList from '../colors/ItemsList';

const typTextiluList = (props) => {
    const listMetaData = {
        newLabel: 'Nový typ textilu',
        headerLabel: 'Typ textilu'
    }

    const metaData = [
        {
            label: 'Kód',
            name: 'kod',
            dataName: 'kod',
            rowsize: 3,
            isReadOnly: true
        },
        {
            label: 'Název',
            name: 'popis',
            dataName: 'popis',
            rowsize: 8

        },
        {
            label: 'Ikona',
            name: 'ikona',
            dataName: 'ikona',
            dataType: "image",
            rowsize: 4
        }
    ];

    return (
        <Fragment>
            <ItemsList itemService={typTextiluService} metaData={metaData} listMetaData={listMetaData} />
        </Fragment>
    )
}

export default typTextiluList

