import React, { Fragment, useEffect, useState, useCallback } from 'react';
import ordersService from '../../services/orders.service'
import customerService from '../../services/customer.service';
import genericService from '../../services/generic.service';
import orderFileService from '../../services/order-files.service';

const displayNoneStyle = { display: "none" }
const OrderListItem = (props) => {
    const oneOrder = props.orderData
    const [customerData, setCustomerData] = useState([]);
    const [orderFilesData, setOrderFilesData] = useState([]);
    const nvl = genericService.nvl
    const nvlBrackets = genericService.nvlBrackets


    const workFileExists = () => {
        if (orderFilesData.length > 0) {
            return (orderFilesData?.filter((file) => file.typ_souboru === 48).length > 0)
        }
        return false
    }

    const previewFileExists = () => {
        if (orderFilesData.length > 0) {
            return (orderFilesData?.filter((file) => file.typ_souboru === 47).length > 0)
        }
        return false
    }

    const fetchData = useCallback(async () => {

        // const customerData = await customerService.getOne(oneOrder.id_zakaznika)
        setCustomerData(oneOrder?.id_zakaznika_zakaznik)
        // const orderFilesData = await orderFileService.getOrderFiles(oneOrder.id_objednavky)
        setOrderFilesData(oneOrder?.soubory_objednavkies)
    }, [oneOrder?.id_zakaznika_zakaznik, oneOrder?.soubory_objednavkies]);


    const onEditOrderHandler = (orderId) => {
         window.location.href = `/orders-detail/${orderId}`;
    }

    useEffect(() => {

        fetchData()

    }, [fetchData]);

    return (
        <Fragment><tr>
            <td className="issue-info">

                {oneOrder.id_objednavky}

                <small>
                    {oneOrder.cislo_objednavky}
                </small>
            </td>
            <td className="issue-info-main">

                {oneOrder.pridelene_cislo_objednavky}

                <small>
                    {oneOrder.externi_cislo_objednavky}
                </small>
            </td>
            <td className="issue-info-long">
                {nvl(oneOrder?.trida) + ' ' + nvl(customerData?.nazev_firmy) + ' ' + nvlBrackets(customerData?.mesto)}
                {/* <small> */}
                <br/>    {(nvl(customerData?.jmeno) + ' ' + nvl(customerData?.prijmeni)).trim()}

                {/* </small> */}
            </td>
            <td className="issue-info-main">

                {oneOrder.datum_zadani_objednavky}
                <br />

                {oneOrder.datum_dodani_objednavky}

            </td>
            <td><button type="button" className="btn btn-sm btn-white" onClick={() => onEditOrderHandler(oneOrder.id_objednavky) }> <i className="fa fa-pencil"></i> </button>
                <span className="pie" style={displayNoneStyle}>0.52,1.041</span><svg className="peity" height="16" width="16"><path d="M 8 8 L 8 0 A 8 8 0 0 1 14.933563796318165 11.990700825968545 Z" fill="#1ab394"></path><path d="M 8 8 L 14.933563796318165 11.990700825968545 A 8 8 0 1 1 7.999999999999998 0 Z" fill="#d7d7d7"></path></svg>
                2d
            </td>
            <td className="text-right">
                {workFileExists() && <button className="btn btn-white btn-xs"> Pracovní</button>}
                {previewFileExists() && <button className="btn btn-white btn-xs">Náhled</button>}
                {oneOrder.faktura_vystavena && <button className="btn btn-white btn-xs"> Faktura</button>}
            </td>
        </tr></Fragment>
    )
}

export default OrderListItem