import { Fragment, useState, useEffect } from "react";
import textilService from "../services/textil.service";

const FiremniCenyBarvaButton = (props) => {


    const textilId = props.textilId
    const velikostId = props.velikostId
    const barvaId = props.barvaId

    const [price, setPrice] = useState(props.price)
    const [isExcluded, setIsExcluded] = useState(false);
    const [isSaveInProgress, setIsSaveInProgress] = useState(false)

    const toggleExcludedTextil = async (textilId, velikostId, barvaId) => {
        setIsSaveInProgress(true)
        const response = await textilService.toggleExcludedCompany(textilId, velikostId, barvaId)
        const status = response.status
        const isExcluded = response.exclude_web
        if (status === "OK") setIsExcluded(isExcluded)
        setIsSaveInProgress(false)

    }

    const onCompanyPriceChage = async (event) => {
        setIsSaveInProgress(true)
        const price = event.target.value
        const response = await textilService.savePrice(textilId, velikostId, barvaId, price)
        setPrice(price)
        setIsSaveInProgress(false)
    }
    useEffect(() => {
        setIsExcluded(props.isExcluded)
        setPrice(props.price)
    }, [props]);
    return (
        <Fragment>
            {/* <fieldset> */}


                {/* <div className="row" >
                    <div className="col"> */}
                        <table align="center" border="0" cellspacing="0" cellpadding="0">
                            <tbody >
                                <tr>
                                    <td>
                                        <input
                                            type="text"
                                            placeholder=".col-md-2"
                                            className="form-control"
                                            value={price}
                                            onChange={onCompanyPriceChage}
                                            size="4"
                                        />
                                    </td>
                                    <td>
                                        <button
                                            className={`btn ${isExcluded ? "btn-danger" : "btn-info"} btn-xs`}
                                            type="button"
                                            id={`zobrazitFirmy#button#${barvaId}#${velikostId}`}
                                            onClick={() => toggleExcludedTextil(textilId, velikostId, barvaId)}

                                        >
                                            <i
                                                id={`zobrazitFirmy#${barvaId}#${velikostId}`}
                                                className="fa fa-check"
                                            ></i>
                                        </button>
                                    </td>
                                    <td>
                                        {isSaveInProgress &&
                                            <div className="sk-spinner sk-spinner-circle">
                                                <div className="sk-circle1 sk-circle"></div>
                                                <div className="sk-circle2 sk-circle"></div>
                                                <div className="sk-circle3 sk-circle"></div>
                                                <div className="sk-circle4 sk-circle"></div>
                                                <div className="sk-circle5 sk-circle"></div>
                                                <div className="sk-circle6 sk-circle"></div>
                                                <div className="sk-circle7 sk-circle"></div>
                                                <div className="sk-circle8 sk-circle"></div>
                                                <div className="sk-circle9 sk-circle"></div>
                                                <div className="sk-circle10 sk-circle"></div>
                                                <div className="sk-circle11 sk-circle"></div>
                                                <div className="sk-circle12 sk-circle"></div>
                                            </div>}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    {/* </div>

                </div> */}

            {/* </fieldset> */}
        </Fragment>
    )
}


export default FiremniCenyBarvaButton
