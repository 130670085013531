import { Fragment } from "react";
import SkolniCenyBarvaButton from "./SkolniCenyBarvaButton";

const SkolniCenyBarva = (props) => {

    const textilId = props.textil
    const excludedTextil = props.excludedTextil
    const barvaId = props.barva.id;


    const getColorStyle = (rgb) => {
        return {
            "zIndex": "1",
            width: "23px",
            height: "23px",
            "borderColor": "black",
            border: "solid 1px",
            "backgroundColor": "rgb(" + rgb + ")",
            visibility: "visible",
        };
    };

    const isExcluded = (barvaId, velikostId, textilId) => {
        let result = false
        const isExcludedData = excludedTextil.filter(
            (excludedTextil) =>
                Number(excludedTextil.velikost_id) === Number(velikostId) && Number(excludedTextil.barva_id) === Number(barvaId) && Number(excludedTextil.textil_id) === Number(textilId)
        );
        if (isExcludedData.length > 0) result = true
        return result
    };

    return (
        <Fragment>
            <tr>
                <td>
                    <button
                        id="colorRect"
                        style={getColorStyle(props.barva.rgb)}
                        className="btn btn-default"
                    />
                    &nbsp;
                    {props.barva.popis}
                </td>
                {props.velikosti.map((oneVelikost) => {
                    const velikostId = oneVelikost.velikost.id;
                    return (
                        <Fragment key={barvaId + "#" + velikostId}>
                            <td>
                                <fieldset>
                                    <div className="row">
                                        <div className="col-sm-1">
                                            <SkolniCenyBarvaButton textilId={textilId} velikostId={velikostId} barvaId={barvaId} isExcluded={isExcluded(barvaId, velikostId, textilId)} />
                                        </div>
                                    </div>
                                </fieldset>
                            </td>
                        </Fragment>
                    );
                })}
            </tr >
        </Fragment >
    );
};

export default SkolniCenyBarva;
