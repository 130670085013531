import { Fragment } from "react";
import auth from '../services/auth.service'
import { Redirect, Route } from "react-router-dom";

function Login() {

    const handleSubmit = async (event) => {
        event.preventDefault();
        const username = event.target[0].value
        const password = event.target[1].value
        const retData = await auth.auth(username, password)
        console.log(retData.accessToken)
        sessionStorage.setItem("accessToken", retData.accessToken);
        window.location.href = "/textil"
    }

    return (

        <div className="middle-box text-center loginscreen animated fadeInDown">
            <div>
                <div>
                    <h1 className="logo-name">IS</h1>
                </div>
                <h3>Vítejte do Kubyx IS</h3>

                <p>Zadejte přihlašovací údaje</p>
                <form className="m-t" role="form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            name="username"
                            // type="email"
                            className="form-control"
                            placeholder="přihlašovací jméno"
                            required=""
                        />
                    </div>
                    <div className="form-group">
                        <input
                            name="password"
                            type="password"
                            className="form-control"
                            placeholder="heslo"
                            required=""
                        />
                    </div>
                    <button type="submit" className="btn btn-primary block full-width m-b">
                        Přihlásit
                    </button>
                </form>
            </div>
        </div>



    );
}

export default Login;
