import React, { Fragment, useEffect, useState, useCallback } from 'react';

const ItemPagination = (props) => {
    const [pageStart, setPageStart] = useState(1);
    const [pageEnd, setPageEnd] = useState(10);
    const [pageRange, setPageRange] = useState([]);
    const [pagesCount, setPagesCount] = useState(1);
    const pageNo = props.pageNo
    const setPageNo = props.setPageNo
    const itemsCnt = props.itemsCnt
    const perPageNo = props.perPageNo
    // const [itemsCnt, setItemsCnt] = useState(1)

    const navigationClass = (currentPageNo) => {
        if (currentPageNo === pageNo) {
            return 'btn btn-white active';
        } else {
            return 'btn btn-white';
        }
    };

    function roundOff(num) {
        var top = 10 - (num % 10) + num;
        if (num % 10 === 0) top = num;
        const bottom = top - 9;
        return { bottom: bottom, top: top };
    }

    const rangeValues = (start, stop, step) =>
        Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

    const changePageNo = useCallback((pageNo) => {
        if (pageNo < 1) pageNo = 1;
        if (pageNo > pagesCount) pageNo = pagesCount;
        const endPageData = roundOff(pageNo);
        var endPage = endPageData.top;
        if (endPage > pagesCount) endPage = pagesCount;
        setPageEnd(endPage);
        setPageStart(endPageData.bottom);
        const rangeData = rangeValues(endPageData.bottom, endPage, 1);
        setPageRange(rangeData);
        setPageNo(pageNo);
    }, [pagesCount, setPageNo]);



    useEffect(() => {
        // setItemsCnt(props.itemsCnt)
        const pagesCount = Math.ceil(itemsCnt / perPageNo)
        setPagesCount(pagesCount);
        changePageNo(Math.max(1, pageNo), pagesCount);
        console.log(pagesCount)
        if (pageNo <= 10) setPageRange(rangeValues(1, Math.min(pagesCount, 10), 1));


    }, [itemsCnt, pageNo, perPageNo, changePageNo]);


    return (
        <Fragment>
            <div className="row">
                <div className="col-lg-12">
                    <div className="text-right">
                        <div className="btn-group">
                            <button type="button" className="btn btn-white" onClick={() => changePageNo(pageStart - 1)}>
                                <i className="fa fa-chevron-left"></i>
                            </button>
                            {pageRange?.map((i) => (
                                <button
                                    key={'navkey' + i}
                                    className={navigationClass(i)}
                                    onClick={() => changePageNo(i)}
                                >
                                    {i}
                                </button>
                            ))}
                            <button
                                type="button"
                                className="btn btn-white"
                                onClick={() => changePageNo(Number(pageEnd) + 1)}
                            >
                                <i className="fa fa-chevron-right"></i>{' '}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ItemPagination