import { BASE_API_URL } from '../constants';

async function getAllData(pageNo, perPageNo, sortBy, sortDir) {
    // const url = 'https://newis-backend.kubyx.cz/barva';
    const url = `${BASE_API_URL}/velikost/${pageNo}/${perPageNo}/${sortBy}/${sortDir}`;
    const data = fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
        .then(function (response) {
            return response.json();
        })
        .then(function (myJson) {
            return myJson;
        });
    console.log(data);
    return data;
}


async function saveData(data) {
    const url = `${BASE_API_URL}/velikost`;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify(data),
    };

    const resData = fetch(url, requestOptions)
        .then(function (response) {
            return response.json();
        })
        .then(function (myJson) {
            return myJson;
        });
    console.log(resData);
    return resData;
}

async function deleteData(data) {
    const url = `${BASE_API_URL}/velikost`;
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify(data),
    };
    const resData = fetch(url, requestOptions)
        .then(function (response) {
            return response.json();
        })
        .then(function (myJson) {
            return myJson;
        });
    console.log(resData);
    return resData;
}

export default {
    getAllData,
    saveData,
    deleteData
};
